import * as React from 'react';
import cx from 'classnames';
import {
  CustomHtmlField,
  DefinitionBlock,
  FieldType,
  RenderError,
  Targets,
} from 'models/publisher/block';
import { useRenderer } from 'hooks/content-blocks';
import { useStyleEditor } from 'contexts/publisher/compose/style';
import { RenderStatusContext } from 'contexts/publisher/compose/render-status';
import { useRenderCache } from 'components/publisher/blocks/instances/useRenderCache';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useUniqueId } from 'hooks/useUniqueId';
import { useProgram } from 'contexts/program';
import { DevReveal, PrettyJson } from 'DevMode';
import { convertSnakeCaseToSentenceCase } from 'utility/text';
import { useCallback } from 'react';
import { useToggle } from 'hooks/useToggle';
import { EditorProps } from '../modal-field-editor/useEditor';
import { Modal as OldEditor } from '../modal-field-editor/Modal';
import { Form as NewEditor } from '../forms/Form';
import { Loading } from './Loading';
import { CodeViewCallback, useEditableInstance } from './useEditableInstance';
import { InlineEditor } from './InlineEditor';
import styles from '../dnd.module.css';
import { DragDropHandle } from '../dnd/DragDropHandle';
import { TabType } from '../panels/ContentDesignTabs';
import { FullHtmlEditor } from './FullHtmlEditor';
import { DynamicBlockEditor } from './DynamicBlock';

const Renderer: React.FC<{
  block: DefinitionBlock;
  onChange: (fieldName: string, data: FieldType) => void;
  blockId: string;
  enableRender?: boolean;
  enableLiveEdits?: boolean;
  onFocus: () => void;
  onDropzoneUploading: (isUploading: boolean) => void;
  codeViewCallback: CodeViewCallback;
  showCodeViewButton?: boolean;
  showEditor: (() => void) | false;
  selectBlock: () => void;
  dragHandleProps: ReturnType<typeof useEditableInstance>['dragHandleProps'];
  isDragging: ReturnType<typeof useEditableInstance>['isDragging'];
}> = ({
  block,
  onChange,
  blockId,
  enableRender,
  enableLiveEdits,
  onFocus: parentOnFocus,
  onDropzoneUploading,
  codeViewCallback,
  showCodeViewButton,
  showEditor,
  selectBlock,
  dragHandleProps,
  isDragging,
}) => {
  const { style } = useStyleEditor();
  const { update } = React.useContext(RenderStatusContext);
  const focus = useToggle();

  const onFocus = useCallback(() => {
    parentOnFocus();
    focus.enable();
  }, [focus, parentOnFocus]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const render = useRenderer(block, style, Targets.web);

  const isCustomHtmlBlock = React.useMemo(() => {
    return block.name === 'custom_html';
  }, [block.name]);

  const isDynamicBlock = React.useMemo(() => {
    return block.name === 'dynamic_block';
  }, [block.name]);

  const html = useRenderCache({
    html: render.html,
    errors: render.errors,
    enableRender,
  });

  const newEditorFlag = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;

  const { id: programId } = useProgram();

  const htmlTemplatesEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.HTMLTemplates'
  ).data?.value;

  const audienceBlockTargetingEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.AudienceBlockTargeting'
  ).data?.value;

  const looksOk = render.errors.length < 1;
  const toolbarId = `inline-editor-toolbar-${useUniqueId()}`;
  const hasPreviousRender = html !== '';
  const isLoading = !hasPreviousRender && looksOk;
  const isInitting = (!hasPreviousRender || newEditorFlag) && !looksOk;

  const isErrored = hasPreviousRender && !looksOk;
  const hasBlockTargeting = !!block.target && block.target.excluded.length > 0;
  React.useEffect(() => update(blockId, looksOk), [blockId, looksOk, update]);

  const [autoOpened, ranAutoOpen] = React.useState(false);
  const autoOpenRefs = React.useRef({ showEditor, selectBlock });
  autoOpenRefs.current = { showEditor, selectBlock };
  React.useEffect(() => {
    if (!autoOpened && isInitting && autoOpenRefs.current.showEditor) {
      autoOpenRefs.current.selectBlock();
      autoOpenRefs.current.showEditor();
      ranAutoOpen(true);
    }
  }, [autoOpened, isInitting, autoOpenRefs]);

  React.useEffect(() => {
    if (isInitting && showEditor) showEditor();
  }, [isInitting, showEditor]);

  const [isHovering, setIsHovering] = React.useState(false);
  const onMouseEnter = () => setIsHovering(true);
  const onMouseLeave = () => setIsHovering(false);

  if (isDynamicBlock && audienceBlockTargetingEnabled) {
    return (
      <>
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {dragHandleProps && (
            <DragDropHandle
              label={convertSnakeCaseToSentenceCase(block.name)}
              dragHandleProps={dragHandleProps}
              isVisible={isHovering || isDragging}
              hasBlockTargeting={hasBlockTargeting}
            />
          )}
          <DynamicBlockEditor
            blockId={blockId}
            block={
              block as DefinitionBlock<{
                dynamic_block: { uuid: string; type: 'dynamic_block' };
              }>
            }
            onLeave={onMouseLeave}
            onChange={onChange}
          />
          {!looksOk && <ErrorsOverlay errors={render.errors} />}
        </div>
      </>
    );
  }

  if (isCustomHtmlBlock && htmlTemplatesEnabled) {
    return (
      <>
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {dragHandleProps && (
            <DragDropHandle
              label={convertSnakeCaseToSentenceCase(block.name)}
              dragHandleProps={dragHandleProps}
              isVisible={isHovering || isDragging}
              hasBlockTargeting={hasBlockTargeting}
            />
          )}
          <FullHtmlEditor
            html={html}
            onChange={onChange}
            onLeave={onMouseLeave}
            blockId={blockId}
            block={block as DefinitionBlock<{ customHtml: CustomHtmlField }>}
          />
          {!looksOk && <ErrorsOverlay errors={render.errors} />}
        </div>
      </>
    );
  }
  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {dragHandleProps && (
        <DragDropHandle
          label={convertSnakeCaseToSentenceCase(block.name)}
          dragHandleProps={dragHandleProps}
          isVisible={(isHovering && !focus.value) || isDragging}
          hasBlockTargeting={hasBlockTargeting}
        />
      )}
      <div className={styles.editorToolbarContainer}>
        <div id={toolbarId} className={styles.editorToolbar} />
      </div>
      <div className="editable" style={{ position: 'relative' }}>
        {isLoading && <Loading />}
        {!isLoading && (
          <InlineEditor
            blockId={blockId}
            html={html}
            block={block}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={focus.disable}
            onDropzoneUploading={onDropzoneUploading}
            codeViewCallback={codeViewCallback}
            showCodeViewButton={showCodeViewButton}
            disableFroala={!enableLiveEdits}
            toolbarId={toolbarId}
          />
        )}
        {isErrored && <ErrorsOverlay errors={render.errors} />}
      </div>
    </div>
  );
};

const ErrorsOverlay: React.FC<{ errors: RenderError[] }> = ({ errors }) => (
  <div className={styles.errorsContain}>
    {errors.map((error) => (
      <div key={JSON.stringify(error)}>
        <span>Error in {error.field_label}:</span>{' '}
        <strong>{error.message}</strong>
      </div>
    ))}
  </div>
);

export const Editable: React.FC<Parameters<typeof useEditableInstance>[0]> = (
  props
) => {
  const instance = useEditableInstance(props);
  return (
    <>
      <div
        id={instance.blockId}
        className={cx(styles.instance, {
          [styles.dragging]: instance.isDragging,
        })}
      >
        <DevReveal view={<PrettyJson value={instance.block} />}>
          <ErrorBoundary ErrorComponent={RuntimeRenderError}>
            <Renderer
              block={instance.block}
              onChange={instance.onChangeFieldData}
              blockId={instance.blockId}
              enableRender={instance.enableRender}
              enableLiveEdits={instance.enableLiveEdits}
              onFocus={instance.selectBlock}
              onDropzoneUploading={instance.onDropzoneUploading}
              codeViewCallback={instance.codeViewCallback}
              showCodeViewButton={instance.showCodeViewButton}
              showEditor={instance.showModalEditor}
              selectBlock={instance.selectBlock}
              dragHandleProps={instance.dragHandleProps}
              isDragging={instance.isDragging}
            />
          </ErrorBoundary>
        </DevReveal>
        <div
          className={cx([styles.menu], {
            [styles.showMenu]: instance.isDeleting,
          })}
        >
          <instance.Controls
            canEdit={instance.canEdit}
            canDuplicate={instance.supportsDuplication}
            supportsCodeView={instance.supportsCodeView}
            canSaveCustomBlock={instance.canSaveCustomBlock}
            showEditor={instance.showModalEditor}
            showVariantsModal={instance.showVariantsModal}
            deleteBlock={instance.deleteSelf}
            duplicateBlock={instance.duplicateSelf}
            selectBlock={instance.selectBlock}
            toggleCodeView={instance.toggleCodeView}
            saveCustomBlock={instance.saveCustomBlock}
            blockId={instance.blockId}
          />
        </div>
      </div>

      {instance.showingFieldModal && instance.canEdit && (
        <Editor
          blockId={instance.blockId}
          block={instance.block}
          field={instance.showingFieldModal}
          onChangeData={instance.onChangeDataByBlockId}
          hideBlockEditor={instance.hideModalEditor}
          defaultTab={TabType.Content}
        />
      )}

      {instance.showingVariantsModal && (
        <Editor
          blockId={instance.blockId}
          block={instance.block}
          field={instance.showingVariantsModal}
          onChangeData={instance.onChangeDataByBlockId}
          hideBlockEditor={instance.hideVariantsModal}
          defaultTab={TabType.Section}
        />
      )}
    </>
  );
};

const RuntimeRenderError: React.FC = () => (
  <div style={{ height: '300px', textAlign: 'center', paddingTop: '80px' }}>
    There was an error rendering this block.
    <br />
    This error has been reported and we are looking into a fix.
  </div>
);

export const Editor: React.FC<EditorProps> = (props) => {
  const { id } = useProgram();
  const { data } = useFeatureFlagsQuery(id, 'Studio.Publish.NewEditors');
  if (!data) return null; // wait until we know which editor to use...

  const EditorImplementation = data.value ? NewEditor : OldEditor;
  // Temporary feature flag wrapper, a short-term suppression.
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <EditorImplementation {...props} />;
};
