import React, { createContext, useContext } from 'react';
import { Design } from 'models/design';
import { useJourneyState } from 'contexts/journeys/journey';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { DeliveryChannel } from 'models/journeys/journey';
import { useNavigate } from '@reach/router';
import { useDesignContext } from 'hooks/design';
import { DesignContext } from 'contexts/design';
import { DrawerState } from '../JourneyDrawer/drawer';
import { useDefaultEmailAddress } from '../../../../../hooks/email-alias';

type JourneyContentDesignerContextProps = {
  isDesignerOpen: boolean;
  closeDesigner: (designId?: number) => void;
  onSelectDesign: (design: Design) => void;
  createDesign: () => void;
  editDesign: () => void;
  inlineEditingEnabled: boolean;
  designerId?: number | 'new';
  setDesignerId: (id: number | 'new') => void;
  isTemplateModalOpen: boolean;
  setIsTemplateModalOpen: (value: boolean) => void;
  navigateToInlineEditor: () => Promise<void>;
};

const JourneyContentDesignerContext = createContext<
  JourneyContentDesignerContextProps
>({
  isDesignerOpen: false,
  closeDesigner: () => {},
  onSelectDesign: () => {},
  createDesign: () => {},
  editDesign: () => {},
  inlineEditingEnabled: false,
  designerId: 'new',
  setDesignerId: () => {},
  isTemplateModalOpen: false,
  setIsTemplateModalOpen: () => {},
  navigateToInlineEditor: () => Promise.resolve(),
});

export const JourneyContentDesignProvider: React.FC = ({ children }) => {
  const [isTemplateModalOpen, setIsTemplateModalOpen] = React.useState(false);
  const [designerId, setDesignerId] = React.useState<number | 'new'>('new');
  const { id: programId } = useProgram();
  const inlineEditingEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Journeys.InlineEditing'
  ).data?.value;
  const { data: defaultEmailAddress } = useDefaultEmailAddress();

  const {
    updateStep,
    activeStep,
    setDrawerState,
    journey,
    isDesignerOpen,
    setIsDesignerOpen,
  } = useJourneyState();
  const designContext = useDesignContext({
    programId,
    id: designerId || 'new',
    redirectOnSave: false,
  });

  const onSelectDesign = React.useCallback(
    (design: Design) => {
      if (!activeStep || activeStep.type !== 'communication') return;
      let updatedChannels: DeliveryChannel[] = activeStep.channels;
      if (activeStep.designId !== Number(design.id)) {
        updatedChannels = activeStep.channels.map((c) =>
          c.name === 'email'
            ? {
                ...c,
                previewText: design.name || '',
                subject: design.name || '',
                emailSenderAlias: c.emailSenderAlias || defaultEmailAddress,
              }
            : c
        );
      }
      const title = design.name || activeStep.title;

      updateStep({
        ...activeStep,
        title,
        approved: false,
        channels: updatedChannels,
        designId: Number(design.id),
      });
      setDesignerId(Number(design.id));
    },
    [activeStep, defaultEmailAddress, updateStep]
  );

  const createDesign = () => {
    setDesignerId('new');
    setIsDesignerOpen(true);
  };

  const ensureDesignerId = (designId?: number) => {
    if (!activeStep || activeStep.type !== 'communication') return;
    if (designId) {
      setDesignerId(designId);
    } else if (activeStep.designId) {
      setDesignerId(Number(activeStep.designId));
    } else {
      setDesignerId('new');
    }
  };
  const journeyPath = `/${programId}/app/journeys/${journey?.id ?? 'new'}`;
  const navigate = useNavigate();
  const navigateToInlineEditor = () => {
    return navigate(`${journeyPath}/edit/design`);
  };
  const navigateToJourneyEdit = () => {
    navigate(`${journeyPath}/edit`);
  };
  const editDesign = () => {
    ensureDesignerId();
    setIsDesignerOpen(true);
    navigateToInlineEditor();
  };
  const closeDesigner = (designId?: number) => {
    setIsDesignerOpen(false);
    ensureDesignerId(designId);
    setDrawerState(DrawerState.Partial);
    navigateToJourneyEdit();
  };

  return (
    <JourneyContentDesignerContext.Provider
      value={{
        isDesignerOpen,
        closeDesigner,
        onSelectDesign,
        createDesign,
        editDesign,
        inlineEditingEnabled,
        designerId,
        setDesignerId,
        isTemplateModalOpen,
        setIsTemplateModalOpen,
        navigateToInlineEditor,
      }}
    >
      <DesignContext.Provider value={designContext}>
        {children}
      </DesignContext.Provider>
    </JourneyContentDesignerContext.Provider>
  );
};

export const useJourneyContentDesigner: () => JourneyContentDesignerContextProps = () => {
  const context = useContext(JourneyContentDesignerContext);
  if (!context) {
    throw new Error(
      'useJourneyContentDesigner must be used within a JourneyContentDesignerProvider'
    );
  }
  return context;
};
