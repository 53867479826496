import { useMemo } from 'react';
import { useTableauDashboardFeatures } from '../../../../../../../hooks/tableau-dashboard';
import { Loading } from '../../../../../../../components/publisher/blocks/instances/Loading';
import { JourneyStepOverviewInsightsTable } from '../JourneyStepOverviewInsightsTable';
import { UserActivityStreamCard } from '../../../../ContentResults/ContentResultsPageV2/MetricsCards/UserActivityStreamCard';
import { formatDate } from '../../../../../../../shared/Charts/Util';
import { JourneyUserActivityTable } from '../JourneyUserActivityTable/JourneyUserActivityTable';

type IDashboardWidgets = {
  workbookId?: string;
  dashboardId: string;
  isDashboardLoading?: boolean;
};

const FEATURE_MAPPING: {
  [key: string]: () => JSX.Element | null;
} = {
  'journeys.overview.insights': () => {
    return <JourneyStepOverviewInsightsTable />;
  },
  'journeys.steps.user_activity_table': () => {
    return <JourneyUserActivityTable />;
  },
  // This one is a test card which is why it looks strange/hard-coded
  user_activity_stream_card: () => (
    <UserActivityStreamCard
      campaignId={1}
      columns={[
        { key: 'user_name', header: 'Name', width: '15%' },
        { key: 'email', header: 'Email', width: '15%' },
        { key: 'channel', header: 'Channel', width: '10%' },
        {
          key: 'last_previewed',
          header: 'Last Previewed',
          width: '15%',
          formatCell: formatDate,
        },
        {
          key: 'last_opened',
          header: 'Last Opened',
          width: '15%',
          formatCell: formatDate,
        },
        {
          key: 'last_engaged',
          header: 'Last Engaged',
          width: '15%',
          formatCell: formatDate,
        },
        {
          key: 'last_activity',
          header: 'Last Activity',
          width: '15%',
          formatCell: formatDate,
        },
      ]}
      hasSearch
    />
  ),
};

export const DashboardWidgets: React.FC<IDashboardWidgets> = ({
  dashboardId,
  isDashboardLoading,
}) => {
  // We'll want to move this into a context later
  const {
    data: features,
    isLoading: isLoadingFeatures,
  } = useTableauDashboardFeatures({ dashboardId });

  const renderFeatures = useMemo(() => {
    if (isLoadingFeatures || features === undefined) {
      return null;
    }

    return features.map(({ key }) => {
      if (FEATURE_MAPPING?.[key] !== undefined) {
        return FEATURE_MAPPING[key]();
      }
      return null;
    });
  }, [features, isLoadingFeatures]);

  // If the dashboard is loading, show nothing since it already shows a loader
  if (isDashboardLoading) {
    return null;
  }

  // If we're still loading after the dashboard is loading, show a loader
  if (isLoadingFeatures) {
    return <Loading />;
  }

  return <>{renderFeatures}</>;
};
