import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';
import { AdminPreferences, User } from 'models/user';
import { Permissions } from 'models/permissions';
import { BulkSelection, UserBulkActionFilters } from 'hooks/common';
import { getActor } from 'services/global-actor-storage';
import axios from 'axios';
import { BulkUploadJob } from 'models/bulk-upload-job';
import { fqApiUrl, PaginationData } from './common';
import { bossanovaDomain, deepCamelcaseKeys, request } from './api-shared';
import {
  AudienceMembershipData,
  AudienceReadData,
  UserExportData,
} from './api-audiences';

export type FetchProps = {
  programId: number;
  page?: number;
  pageSize?: number;
  search?: string;
  query?: string;
  filters?: {
    withoutAliasedUsers?: boolean;
    withoutHiddenOnProgram?: boolean;
  };
  audienceQuery?: boolean;
  statuses?: string[];
  roles?: string[];
  audiences?: string[];
  scopes?: string[];
};

export type UserData = {
  accessibleBrands: Array<{ id: number; name: string }>;
  accessiblePrograms: Array<{
    id: number;
    name: string;
    brandId: number;
  }>;
  accessibleRegions: Array<{
    current: boolean;
    name: string;
    newStudioUrl: string;
  }>;
  adminPreferences?: AdminPreferences;
  avatarUrl?: string;
  firstName?: string;
  id: number;
  lastName?: string;
};

export type NewUserData = {
  adminPermission?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  permissions?: Record<string, boolean>;
  sendInviteEmail?: 'true' | 'false';
};

export type UserCollectionData = {
  data: Array<UserData>;
  meta: PaginationData;
};

export type BulkUploadJobStates = {
  administrator: number;
  analyst: number;
  members: number;
  publisher: number;
  programManager: number;
  topicContributer: number;
  duplicate: number;
  imported: number;
  totalItems: number;
  lineErrors: number;
};

export const formatUsersResponse = async (
  // eslint-disable-next-line
  response: any
): Promise<UserCollectionData> => {
  const result = camelcaseKeys(await response.json(), { deep: true });
  result.data = result.data
    .filter((user: { type: string }) => user.type !== 'user/missing')
    .map((user: { id: number; attributes: Omit<UserData, 'id'> }) => ({
      id: user.id,
      ...user.attributes,
    }));

  return result;
};

export const fetchCurrentUser = async (): Promise<UserData | undefined> => {
  if (!getActor()) {
    return undefined;
  }
  const response = await request(`${bossanovaDomain}/samba/user`);
  if (response.status === 200) {
    const parsed_response = response
      .json()
      .then((user) => camelcaseKeys(user, { deep: true }));
    const current_user = await parsed_response;

    if (current_user.accessiblePrograms.length > 0) {
      return current_user;
    }
    return undefined;
  }
  if (response.status === 401) {
    return undefined;
  }
  throw new Error(`Authentication error: ${response.status}`);
};

export const fetchCurrentUserPermissions = async (
  programId: number
): Promise<Permissions | undefined> => {
  const response = await request(
    `${bossanovaDomain}/samba/user/permissions?program_id=${programId}`
  );
  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }
  throw new Error(`Authentication error: ${response.status}`);
};

const fetchUsersFromProgram = async (
  programId: number,
  page?: number,
  pageSize?: number,
  search?: string,
  filters?: {
    withoutAliasedUsers?: boolean;
    withoutHiddenOnProgram?: boolean;
  },
  statuses?: string[],
  roles?: string[],
  scopes?: string[],
  audiences?: string[]
): Promise<UserCollectionData> => {
  const query = qs.stringify(
    snakecaseKeys({
      page,
      statuses,
      roles,
      audiences,
      scopes,
      filter: filters, // boss calls it `filter` but plural looks better in the component
      q: search,
      perPage: pageSize,
      is_program_membership: true,
    }),
    { arrayFormat: 'brackets', encodeValuesOnly: true }
  );

  const url = `${bossanovaDomain}/samba/programs/${programId}/users?true&${query}`;
  const response = await request(url);
  if (response.status === 200) {
    const result = camelcaseKeys(await response.json(), { deep: true });

    if (result.meta) {
      result.meta.currentPage = result.meta.page;
    }

    return result;
  }
  throw new Error(`Error fetching users: ${response.status}`);
};

const fetchUsersFromAudience = async (
  programId: number,
  page?: number,
  pageSize?: number,
  query?: string,
  search?: string
): Promise<UserCollectionData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/groups/query/full`;
  const q = (search ? `${search} AND (${query})` : query) || '';

  const data = snakecaseKeys({
    page,
    perPage: pageSize,
    q,
    visibility: 'visible',
  });
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    const result = camelcaseKeys(await response.json(), { deep: true });

    if (result.meta) {
      result.meta.currentPage = result.meta.pageNumber;
      result.meta.totalRecords = result.meta.totalObjects;
    }
    result.data = result.data.map(
      (a: { id: unknown; attributes: Record<string, unknown> }) => {
        return {
          id: a.id,
          ...a.attributes,
        };
      }
    );
    return result;
  }
  throw new Error(`Error fetching users: ${response.status}`);
};

export const fetchUsers = async (
  props: FetchProps
): Promise<UserCollectionData> => {
  const {
    programId,
    page,
    pageSize,
    search,
    query,
    filters,
    audienceQuery,
    statuses,
    scopes,
    audiences,
    roles,
  } = props;

  return audienceQuery
    ? fetchUsersFromAudience(programId, page, pageSize, query, search)
    : fetchUsersFromProgram(
        programId,
        page,
        pageSize,
        search,
        filters,
        statuses,
        roles,
        scopes,
        audiences
      );
};

export async function fetchByIds(
  ids: Array<number>,
  programId: number
): Promise<UserCollectionData> {
  const response = await request(
    fqApiUrl(`samba/programs/${programId}/users/bulk`),
    {
      method: 'POST',
      body: JSON.stringify({ user_ids: ids }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.status === 200) {
    return formatUsersResponse(response);
  }

  throw new Error(`Error fetching users: ${response.status}`);
}

export const fetchUser = async (
  programId: number,
  userId: number
): Promise<UserData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/${userId}`;
  const response = await request(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error activate users: ${response.status}`);
};

export const createUser = async (
  programId: number,
  data: NewUserData
): Promise<UserData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys(data)),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 201) {
    return camelcaseKeys(await response.json());
  }

  throw new Error(`Error creating users: ${response.status}`);
};

export const updateAdminPreferences = async (
  data: AdminPreferences
): Promise<AdminPreferences> => {
  const url = `${bossanovaDomain}/samba/studio_preferences`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ studio_preferences: data })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }

  throw new Error(`Error updating admin preferences: ${response.status}`);
};

export const forgetUsers = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: UserBulkActionFilters
): Promise<UserData> => {
  const { search, statuses, roles } = filterConfig || {};
  const query = qs.stringify(
    {
      q: search,
      statuses,
      roles,
      is_program_membership: true,
    },
    { arrayFormat: 'brackets', encodeValuesOnly: true }
  );
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/forget?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 202) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error activate users: ${response.status}`);
};

export const activateUsers = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: UserBulkActionFilters
): Promise<UserData> => {
  const { search, statuses, roles, audiences, scopes } = filterConfig || {};
  const query = qs.stringify(
    {
      q: search,
      statuses,
      roles,
      audiences,
      scopes,
      is_program_membership: true,
    },
    { arrayFormat: 'brackets', encodeValuesOnly: true }
  );
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/unblock/bulk?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error activate users: ${response.status}`);
};

export const deactivateUsers = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: UserBulkActionFilters
): Promise<UserData> => {
  const { search, statuses, roles, audiences, scopes } = filterConfig || {};
  const query = qs.stringify(
    {
      q: search,
      statuses,
      roles,
      audiences,
      scopes,
      is_program_membership: true,
    },
    { arrayFormat: 'brackets', encodeValuesOnly: true }
  );
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/block/bulk?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error deactivate users: ${response.status}`);
};

export const hideUsers = async (
  programId: number,
  bulkSelection: BulkSelection,
  filterConfig: UserBulkActionFilters
): Promise<UserData> => {
  const { search, statuses, roles } = filterConfig || {};
  const query = qs.stringify(
    {
      q: search,
      statuses,
      roles,
      is_program_membership: true,
    },
    { arrayFormat: 'brackets', encodeValuesOnly: true }
  );
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/hide/bulk?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection })),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error hide users: ${response.status}`);
};

export const inviteUsers = async (
  programId: number,
  ids: string[] | undefined,
  excludedIds: string[] | undefined,
  sendExperienceEmail: string,
  sendStudioEmail: string,
  query?: string,
  audiences?: string[],
  scopes?: string[]
): Promise<Record<string, never>> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/invite`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      filter: {
        ids,
        excluded_ids: excludedIds,
        query,
        audiences,
        scopes,
      },
      program_id: programId,
      send_experience_email: sendExperienceEmail,
      send_studio_email: sendStudioEmail,
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error inviting users: ${response.status}`);
};

export const exportUsers = async (
  programId: number,
  ids: number[] | undefined
): Promise<{ data: UserExportData }> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/user_exports/`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      filter: {
        ids,
      },
      program_id: programId,
      type: 'users',
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 201) {
    return camelcaseKeys(await response.json(), { deep: true });
  }
  throw new Error(`Error exporting users: ${response.status}`);
};

export const bulkExportUsers = async (
  programId: number,
  ids: string[] | undefined,
  excludedIds: string[] | undefined,
  query?: string,
  statuses?: string | string[],
  roles?: string | string[],
  audiences?: string[],
  scopes?: string[]
): Promise<Record<string, never>> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/user_exports`;
  const excluded_ids =
    excludedIds && excludedIds?.length > 0 ? excludedIds : undefined;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      filter: {
        ids,
        excluded_ids,
        query,
        status: statuses,
        role: roles,
        audiences,
        scopes,
      },
      program_id: programId,
      type: 'users',
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 201) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error exporting users: ${response.status}`);
};

export const deactivateUser = async (
  programId: number,
  userId: number
): Promise<UserData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/${userId}/block`;
  const response = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error deactivating users: ${response.status}`);
};

export const activateUser = async (
  programId: number,
  userId: number
): Promise<UserData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/${userId}/unblock`;
  const response = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error activating users: ${response.status}`);
};

export const forgetUser = async (
  programId: number,
  userId: number
): Promise<UserData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/forget`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({
      filter: {
        ids: [userId],
      },
      program_id: programId,
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 202) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error forgetting users: ${response.status}`);
};

export const generatePasswordResetLink = async (
  programId: number,
  userId: number
): Promise<{ url: string }> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/${userId}/generate_password_reset_link`;
  const response = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error deactivating users: ${response.status}`);
};

export const hideUser = async (
  programId: number,
  userId: number
): Promise<UserData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/${userId}/hide`;
  const response = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error hiding users: ${response.status}`);
};

export const unHideUser = async (
  programId: number,
  userId: number
): Promise<UserData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/${userId}/display`;
  const response = await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error hiding users: ${response.status}`);
};

export const confirmUser = async (
  programId: number,
  userId: number
): Promise<UserData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/${userId}/bypass_identity_verification`;
  const response = await request(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }
  throw new Error(`Error confirming user: ${response.status}`);
};

export const importUsersFromFile = async (
  programId: number,
  file: File
): Promise<BulkUploadJob> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/bulk_upload_jobs`;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('new_studio', 'true');
  const actor = getActor();
  if (!actor) {
    throw new Error('Not logged in.');
  }

  const response = await axios.post(url, formData, {
    headers: {
      ...actor.headers,
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status === 200) {
    return camelcaseKeys(response.data);
  }

  throw new Error(`Error importing users: ${response.status}`);
};

export const updateUser = async (
  programId: number,
  userId: number,
  data: User
): Promise<UserData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/${userId}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys(data)),
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json());
  }

  throw new Error(`Error updating user: ${response.status}`);
};

export async function fetchUserMemberships(
  programId: string | number,
  userId: string | number
): Promise<AudienceMembershipData[]> {
  const response = await request(
    `${bossanovaDomain}/v2/tenants/program:${programId}/user_memberships/user/${userId}`
  );

  if (response.status === 200) {
    const result: AudienceMembershipData[] = deepCamelcaseKeys(
      await response.json()
    );
    return result;
  }

  throw new Error(
    `Error fetching personalized fields files: ${response.status}`
  );
}
export const fetchUserAudiences = async (
  programId: string | number,
  userId: string | number
): Promise<AudienceReadData[]> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/${userId}/groups`;
  const response = await request(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }
  throw new Error(`Error fetching user audiences: ${response.status}`);
};
