import { DateTime } from 'luxon';
import { TimeOfDay } from 'models/journeys/journey';

export function nextTriggerDate(
  timeOfDay: TimeOfDay,
  timeZone: string
): DateTime {
  const now = DateTime.now().setZone(timeZone);
  let nextDate = now.set({
    hour: timeOfDay.hour,
    minute: timeOfDay.minute,
    second: 0,
    millisecond: 0,
  });

  if (nextDate < now) {
    nextDate = nextDate.plus({ day: 1 });
  }

  return nextDate;
}
