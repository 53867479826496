import { useQuery } from 'react-query';
import { QueryResponse } from './common';
import { useProgram } from '../contexts/program';
import {
  fetchTableauDashboardFeatures,
  TableauDashboardFeaturesData,
} from '../services/api-tableau-dashboards';

export const useTableauDashboardFeatures = (props: {
  dashboardId: string;
}): QueryResponse<TableauDashboardFeaturesData> => {
  const { id: programId } = useProgram();
  const { dashboardId } = props;
  const { isLoading, error, data } = useQuery<
    { data: TableauDashboardFeaturesData },
    Error
  >(
    ['tableau-dashboard-features', { programId, dashboardId }],
    () =>
      fetchTableauDashboardFeatures({
        programId,
        dashboardId,
      }),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data,
  };
};

export function getFeatureMetaData<T>(metaData: string): T {
  return JSON.parse(metaData) as T;
}
