import React from 'react';
import type { Step } from 'models/journeys/journey';
import { useJourneyState } from 'contexts/journeys/journey';
import { useJourneyInsightsMetricsQuery } from '../../../../../../../hooks/journeyInsightsMetrics';
import { useProgram } from '../../../../../../../contexts/program';
import { Loading } from '../../../../../../../components/publisher/blocks/instances/Loading';
import { NoDataChart } from '../../../../../../../shared/Charts/NoDataChart';
import { ReportingSection } from './ReportingSection';
import { CompactStepCard } from './Cards/CompactStepCard';
import { ChannelDetailCard } from './Cards/ChannelDetailCard';

import styles from '../step-reporting.module.css';
import { useFeatureFlagsQuery } from '../../../../../../../hooks/feature-flags';

export const StepReportingV2: React.FC<{
  step: Step;
}> = ({ step }) => {
  const { id } = useProgram();
  const journeyState = useJourneyState();
  const { journey, currentGraph } = journeyState;

  if (
    id === undefined ||
    journey?.id === undefined ||
    journey?.currentActivationId === undefined ||
    currentGraph?.id === undefined ||
    currentGraph?.executionId === undefined
  ) {
    return <Loading />;
  }

  return (
    <StepReportingV2Inner
      step={step}
      programId={id}
      journeyId={journey.id}
      activationId={journey.currentActivationId}
    />
  );
};

const StepReportingV2Inner: React.FC<{
  programId: number;
  journeyId: number;
  activationId: number;
  step: Step;
}> = ({ programId, journeyId, activationId, step }) => {
  const { isLoading, data } = useJourneyInsightsMetricsQuery({
    journeyId,
    activationId,
    stepId: step.id || 0,
  });

  const {
    data: notificationCenterFlag,
    isLoading: flagLoading,
  } = useFeatureFlagsQuery(programId, 'EE.NotificationCenter');

  if (isLoading || flagLoading) {
    return <Loading />;
  }

  if (!data) {
    return (
      <div className={styles.noDataContainer}>
        <NoDataChart />
      </div>
    );
  }

  const tooltip = notificationCenterFlag?.value
    ? 'The total number of communications successfully delivered across all channels. This may exceed the total number of members targeted if multiple delivery channels are configured.'
    : undefined;

  /**
   * We decided to hardcode these values. I'm sorry.
   */
  const {
    communicationsSent,
    communicationsDelivered,
    uniqueEmailOpened,
    emailDelivered,
    uniqueEmailLinkClicked,
    emailSent,
    notificationCenterMessageDelivered,
    notificationCenterMessageOpened,
    notificationCenterMessageSent,
    mobilePushDelivered,
    mobilePushOpened,
    mobilePushSent,
  } = data;

  return (
    <>
      <ReportingSection title="Overview">
        <div className={styles.reportingSectionBody}>
          <CompactStepCard
            numerator={communicationsDelivered}
            denominator={communicationsSent}
            units="Communications Sent"
            label="Communications Delivered"
            tooltip={tooltip}
          />
        </div>
      </ReportingSection>
      <ReportingSection title="Delivery Channels">
        <div className={styles.reportingSectionBody}>
          <ChannelDetailCard
            icon="mail"
            label="Email"
            content={{
              children: [
                { label: 'Sent', value: emailSent, children: [] },
                { label: 'Delivered', value: emailDelivered, children: [] },
                { label: 'Opened', value: uniqueEmailOpened, children: [] },
                {
                  label: 'Clicked',
                  value: uniqueEmailLinkClicked,
                  children: [],
                },
              ],
            }}
          />
          {notificationCenterFlag?.value ? (
            <>
              <ChannelDetailCard
                icon="notifications"
                label="Notification Center"
                content={{
                  children: [
                    {
                      label: 'Sent',
                      value: notificationCenterMessageSent,
                      children: [],
                    },
                    {
                      label: 'Delivered',
                      value: notificationCenterMessageDelivered,
                      children: [],
                    },
                    {
                      label: 'Opened',
                      value: notificationCenterMessageOpened,
                      children: [],
                    },
                  ],
                }}
              />

              <ChannelDetailCard
                icon="phone_iphone"
                label="Push Notification"
                content={{
                  children: [
                    { label: 'Sent', value: mobilePushSent, children: [] },
                    {
                      label: 'Delivered',
                      value: mobilePushDelivered,
                      children: [],
                    },
                    {
                      label: 'Opened',
                      value: mobilePushOpened,
                      children: [],
                    },
                  ],
                }}
              />
            </>
          ) : (
            <div />
          )}
        </div>
      </ReportingSection>
    </>
  );
};
