// 1) This should be _the only file_ that imports from `@socialchorus/donkey`
// 2) This exports the types Kai can use.
// 3) This exports a copy of selected donkey functions

/* Part 1 */
/* eslint-disable-next-line import/no-unresolved */
import * as donkey from '@socialchorus/donkey';
import Color from 'color';
import { clone, deepMerge } from 'utility/deep-merge';

/* Part 2 */
export type DataBlock<
  T extends donkey.FieldData = donkey.FieldData
> = donkey.DataBlock<T>;
export type DefinitionBlock<
  T extends donkey.FieldData = donkey.FieldData
> = donkey.DefinitionBlock<T>;
export type FieldDefinition = donkey.FieldDefinition;
export type FieldData = donkey.FieldData;
export type FieldType = donkey.FieldData[string];
export type RenderError = donkey.RenderError;
export type RenderingContext = donkey.RenderingContext;
export type RenderFlags = donkey.RenderFlags;
export type RenderingVariables = donkey.RenderingVariables;
export type Field = donkey.Field;
export type AttachmentFieldData = donkey.AttachmentField;
export type BoxIntegrationFieldData = donkey.BoxIntegrationField;
export type DateFieldData = donkey.DateField;
export type LinkFieldData = donkey.LinkField;
export type ButtonLinkFieldData = donkey.ButtonLinkField;
export type SocialFieldData = donkey.SocialField;
export type ImageFieldData = donkey.ImageField;
export type PollFieldData = donkey.PollField;
export type IframeFieldData = donkey.IframeField;
export type PlainTextFieldData = donkey.PlainTextField;
export type RichTextFieldData = donkey.RichTextField;
export type ComplexRichTextFieldData = donkey.ComplexRichTextField;
export type SimpleTextFieldData = donkey.SimpleTextField;
export type UserFieldData = donkey.UserField;
export type VideoFieldData = donkey.VideoField;
export type DynamicBlockFieldData = donkey.DynamicBlockField;
export type StringVariableFieldData = donkey.StringVariableField;
export type BooleanVariableFieldData = donkey.BooleanVariableField;
export type InputVariableFieldData = donkey.InputVariableField;
export type ComplexRichTextField = donkey.ComplexRichTextField;
export type ComplexRichTextsField = donkey.ComplexRichTextsField;
export type CustomHtmlField = donkey.CustomHtmlField;
export type TextFieldData =
  | PlainTextFieldData
  | SimpleTextFieldData
  | RichTextFieldData;

// variable references
export type BooleanVariableRef = donkey.BooleanVariableRef;
export type StringVariableRef = donkey.StringVariableRef;
export type NumberVariableRef = donkey.NumberVariableRef;
export type BooleanVariableValue = donkey.BooleanVariableValue;
export type StringVariableValue = donkey.StringVariableValue;
export type NumberVariableValue = donkey.NumberVariableValue;
export type MaybeVariableValue = donkey.MaybeVariableValue;
export type InputVariableRef = donkey.InputVariableRef;
export type InputBooleanVariableRef = donkey.InputBooleanVariableRef;
export type InputStringVariableRef = donkey.InputStringVariableRef;
export type InputNumberVariableRef = donkey.InputNumberVariableRef;

export type LinkType = donkey.LinkType;
export type SocialType = donkey.SocialType;
export type PollPreset = donkey.Preset;

// styles
export type Styling = donkey.Styling;
export type VariableStyling = donkey.VariableStyling;
export type StyleData = donkey.StyleData;
export type VariableStyleData = donkey.VariableStyleData;
export type StyleOptions = donkey.StyleOptions;
export type Style = donkey.Style;
export type CustomFont = donkey.CustomFont;
export type Formatting = donkey.Formatting;
export type FormatData = donkey.FormatData;
export type FormatOptions = donkey.FormatOptions;

export const hostedUrls = {
  blankImage:
    'https://lib.onfirstup.com/content_blocks/defaults/img-placeholder.png',
};

// enum

export enum Targets {
  web = 'web',
  email = 'email',
  ios = 'ios',
  android = 'android',
  microapp = 'microapp',
}

/* Part 3 */

// BEGIN EXPORT FROM `donkey/src/blocks/fields/variable.ts`
export function isStringVariableValue(
  variable: MaybeVariableValue
): variable is StringVariableValue {
  if (!variable) return false;
  if (typeof variable !== 'object') return false;
  const { value, default: defaultValue } = variable as StringVariableValue;
  if (value && typeof value === 'string') return true;
  // @ts-expect-error donkey uses a different typescript version
  return defaultValue && typeof defaultValue === 'string';
}
export function isBooleanVariableValue(
  variable: MaybeVariableValue
): variable is BooleanVariableValue {
  if (!variable) return false;
  if (typeof variable !== 'object') return false;
  const { value, default: defaultValue } = variable as BooleanVariableValue;
  if (value !== undefined && typeof value === 'boolean') return true;
  return defaultValue !== undefined && typeof defaultValue === 'boolean';
}
export function isNumberVariableValue(
  variable: MaybeVariableValue
): variable is NumberVariableValue {
  if (!variable) return false;
  if (typeof variable !== 'object') return false;
  const { value, default: defaultValue } = variable as NumberVariableValue;
  if (value && typeof value === 'number') return true;
  return defaultValue !== undefined && typeof defaultValue === 'number';
}

const derivedBooleanDefaults = {};
export function getBooleanValue(
  input: MaybeVariableValue,
  variables?: RenderingVariables
): boolean {
  if (isBooleanVariableValue(input)) {
    return (
      {
        ...variables,
        ...derivedBooleanDefaults,
      }?.[input.value] ?? input.default
    ); // uses `??` to avoid clobbering `false`
  }
  return !!input;
}

const derivedStringDefaults = { text_color: '' };
export function getStringValue(
  input: MaybeVariableValue,
  variables?: RenderingVariables
): string {
  if (isStringVariableValue(input)) {
    return (
      {
        ...variables,
        ...derivedStringDefaults,
      }?.[input.value] || input.default
    ); // uses `||` to provide defaults for `undefined` and ""
  }
  return input === undefined ? '' : `${input}`;
}

const derivedNumberDefaults = {};
export function getNumberValue(
  input: MaybeVariableValue,
  variables?: RenderingVariables
): number {
  if (isNumberVariableValue(input)) {
    return (
      {
        ...variables,
        ...derivedNumberDefaults,
      }?.[input.value] || input.default
    );
  }
  return 0;
}

// BEGIN EXPORT FROM `donkey/src/blocks/processors/styling.ts`

const DEFAULT_TEXT_COLOR_VARS: Partial<VariableStyling['colors']> = {
  highlights: { value: 'text_color', default: '#EEEEEE' },
  headlines: { value: 'text_color', default: '#041918' },
  subhead: { value: 'text_color', default: '#ACACAC' },
  title: { value: 'text_color', default: '#000000' },
  subtitle: { value: 'text_color', default: '#ACACAC' },
  body: { value: 'text_color', default: '#000000' },
  caption: { value: 'text_color', default: '#747474' },
  link: { value: 'text_color', default: '#03A4FF' },
};

// Ensure a blocks page color does not obscure the text by adjusting the text color as needed:
// - If the page color is considered dark, then the text color will be white.
// - if the page color is considered light, then the text will be black.
function resolveTextColor(
  styleData: VariableStyleData,
  variables: RenderingVariables
): Styling['colors'] {
  const textColors: Partial<Styling['colors']> = {};
  const textElements = Object.keys(DEFAULT_TEXT_COLOR_VARS);

  if (isStringVariableValue(styleData?.colors?.page)) {
    const isLight = Color(
      // @ts-expect-error donkey uses a different typescript version
      getStringValue(styleData.colors.page, variables)
    ).isLight();
    const textColor = isLight ? '#000000' : '#FFFFFF';

    textElements.forEach((element) => {
      // @ts-expect-error donkey uses a different typescript version
      if (isStringVariableValue(styleData.colors[element])) {
        // @ts-expect-error donkey uses a different typescript version
        textColors[element] = textColor;
      }
    });
  }

  return textColors as Styling['colors'];
}

function resolveColorVariables(
  styleData: VariableStyleData,
  variables: RenderingVariables
): Styling['colors'] {
  const colors: Partial<Styling['colors']> = {};

  if (styleData.colors)
    Object.entries(styleData.colors).forEach(([key, value]) => {
      // @ts-expect-error donkey uses a different typescript version
      colors[key] = getStringValue(value, variables);
    });

  return colors as Styling['colors'];
}

export function resolveVariableStyling(
  styleData: VariableStyleData,
  variables: RenderingVariables
): Styling {
  const styles = clone(styleData);
  styles.colors = deepMerge(
    styles.colors,
    resolveColorVariables(styleData, variables)
  );
  styles.colors = deepMerge(
    styles.colors,
    resolveTextColor(styleData, variables)
  );

  return styles as Styling;
}
// END EXPORT FROM `donkey/src/blocks/processors/styling.ts`

// START EXPORT FROM donkey/src/blocks/fields/image.ts
export const validLegacyImageField = (image: ImageFieldData): boolean => {
  return !!(!image.processed && !image.input_url && image.hosted_url);
};
// END EXPORT FROM donkey/src/blocks/fields/image.ts
