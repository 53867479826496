import * as React from 'react';
import cx from 'classnames';
import { useUniqueId } from 'hooks/useUniqueId';
import styles from './content-previews.module.css';
import compose from '../theme/compose.module.css';

export const Switcher = <T extends { name: string; icon: React.FC }>({
  tab,
  tabs,
  setTab,
  className,
}: {
  tab: T;
  tabs: T[];
  setTab: (tab: T) => void;
  className?: string;
}): React.ReactElement => {
  const id = useUniqueId();
  React.useEffect(() => {
    const div = document.getElementById(id);
    if (!div) return;
    let parent = div.parentElement;
    while (parent && parent.className.indexOf(compose.main) < 0) {
      parent = parent.parentElement;
    }
    if (!parent) return;
    // Starts out invisible, to avoid jumping around
    div.style.opacity = '1';
  }, [id]);

  return (
    <div className={cx(className, styles.switcher)} id={id}>
      {tabs.map((t) => (
        <button
          key={t.name}
          type="button"
          onClick={() => setTab(t)}
          className={cx({ [styles.active]: t.name === tab.name })}
        >
          <t.icon />
        </button>
      ))}
    </div>
  );
};

Switcher.defaultProps = {
  className: '',
};
