import { DefinitionBlock, DynamicBlockFieldData } from 'models/publisher/block';
import React, { useState } from 'react';
import { Box } from 'DesignSystem/Components';
import { Icon, IconButton } from '@socialchorus/shared-ui-components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { DynamicBlock } from 'models/dynamic_blocks/dynamic_block';
import { useProgram } from 'contexts/program';
import { DynamicBlockVariant } from 'models/dynamic_blocks/dynamic_block_variant';
import * as Text from 'DesignSystem/Typography';
import { useFlashMessage } from 'contexts/flasher';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';
import { Input } from 'DesignSystem/Form';
import styles from './editor.module.css';
import { DefaultContentBlock } from './DefaultContentBlock';

const MAX_VARIANTS = 10;

export const DynamicBlockEditor: React.FC<{
  blockId?: string;
  block: DefinitionBlock<{
    dynamic_block: { uuid: string; type: 'dynamic_block' };
  }>;
  onLeave?: () => void;
  onChange?: (fieldName: string, data: DynamicBlockFieldData) => void;
}> = ({ block }) => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  // get the dynamic block by from the context by uuid...
  const initialDynamicBlock: DynamicBlock = {
    id: '1',
    resource_id: 1,
    resource_type: 'Content',
    program_id: programId,
    uuid: block.field_data.dynamic_block.uuid,
    status: 'completed',
    dynamic_block_variants: [
      {
        id: 1,
        uuid: 'uuid_foo order 2',
        program_id: programId,
        dynamic_block_id: 1,
        name: 'foo',
        order: 2,
      },
      {
        id: 2,
        uuid: 'uuid_bar order 1',
        program_id: programId,
        dynamic_block_id: 1,
        name: 'bar',
        order: 1,
      },
      {
        id: 3,
        uuid: 'uuid_baz order 3',
        program_id: programId,
        dynamic_block_id: 1,
        name: 'baz',
        default: true,
        order: 3,
      },
    ],
  };

  // backend needs to make sure that the order is unique

  const [dynamicBlockVariants, setDynamicBlockVariants] = useState<
    DynamicBlock['dynamic_block_variants']
  >(
    initialDynamicBlock.dynamic_block_variants.sort((a, b) => a.order - b.order)
  );

  const hasVariants = dynamicBlockVariants && dynamicBlockVariants.length > 0;
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const currentVariant = dynamicBlockVariants[currentIndex];

  const isFirstVariantSelected = currentIndex === 0;
  const isLastVariantSelected =
    currentIndex === dynamicBlockVariants.length - 1;

  const selectPreviousVariant = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const previousIndex = isFirstVariantSelected
      ? currentIndex
      : currentIndex - 1;
    setCurrentIndex(previousIndex);
  };

  const selectNextVariant = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const nextIndex = isLastVariantSelected ? currentIndex : currentIndex + 1;
    setCurrentIndex(nextIndex);
  };

  const appendVariant = (
    e: React.MouseEvent<HTMLButtonElement>,
    source?: DynamicBlockVariant
  ) => {
    e.stopPropagation();
    const { length } = dynamicBlockVariants;
    if (length >= MAX_VARIANTS) {
      setFlashMessage({
        severity: 'error',
        message: `You can only have ${MAX_VARIANTS} variants`,
      });
    } else {
      const fields = {
        order: isLastVariantSelected
          ? currentVariant.order
          : currentVariant.order + 1,
        uuid: uuidv4(),
        default: false,
      } as Partial<DynamicBlockVariant>;
      const newVariant = source
        ? {
            ...source,
            ...fields,
          }
        : ({
            uuid: uuidv4(),
            program_id: programId,
            dynamic_block_id: 1,
            name: 'Untitled variant',
            ...fields,
          } as DynamicBlockVariant);

      const updatedVariants = isLastVariantSelected
        ? dynamicBlockVariants.map((variant) => {
            if (variant.uuid === currentVariant.uuid) {
              return { ...variant, order: newVariant.order + 1 };
            }
            return { ...variant };
          })
        : dynamicBlockVariants.map((variant) => {
            if (variant.order > currentVariant.order) {
              return { ...variant, order: variant.order + 1 };
            }
            return variant;
          });

      updatedVariants.push(newVariant);

      const orderedDynamicBlockVariants = updatedVariants.sort(
        (a, b) => a.order - b.order
      );

      const newIndex = orderedDynamicBlockVariants.findIndex(
        (v) => v.uuid === newVariant.uuid
      );
      setDynamicBlockVariants(orderedDynamicBlockVariants);
      setCurrentIndex(newIndex);
    }
  };

  const updateName = (name: string) => {
    setDynamicBlockVariants((prevVariants) =>
      prevVariants.map((variant) =>
        variant.uuid === currentVariant.uuid ? { ...variant, name } : variant
      )
    );
  };

  return (
    <Box
      width="100%"
      height={338}
      className={styles.wrapper}
      background={Text.background.grayTintLight}
    >
      <Box
        height={82}
        className={styles.header}
        background={Text.background.grayTintDark}
      >
        <Flex className={styles.containerName}>
          <Text.Caption semibold color={Text.color.gray40}>
            Dynamic Container
          </Text.Caption>
        </Flex>
        <Flex className={styles.controlsWrapper}>
          <Flex className={styles.navigationButtons}>
            <IconButton
              disabled={isFirstVariantSelected}
              className={cx(styles.controlsButton, {
                [styles.disabled]: isFirstVariantSelected,
              })}
              onClick={selectPreviousVariant}
              iconName="arrow_back"
              size="compact"
            />
            <Box>
              <Text.Body>
                {currentIndex + 1}/{dynamicBlockVariants.length}
              </Text.Body>
            </Box>
            <IconButton
              disabled={isLastVariantSelected}
              className={cx(styles.controlsButton, {
                [styles.disabled]: isLastVariantSelected,
              })}
              size="compact"
              onClick={selectNextVariant}
              iconName="arrow_forward"
            />
          </Flex>
          <Box width={362}>
            <Text.Caption color={Text.color.gray90}>
              {currentVariant.default ? (
                <Box padding={[0, 0, 0, 8]}>
                  <Text.Caption color={Text.color.gray90}>
                    Default Content
                  </Text.Caption>
                </Box>
              ) : (
                <Box className={styles.editableTitleWrapper}>
                  <Icon size={14}>edit</Icon>
                  <Input value={currentVariant.name} onChange={updateName} />
                </Box>
              )}
            </Text.Caption>
          </Box>
          <Flex className={styles.actionButtons}>
            <IconButton onClick={appendVariant} iconName="add" size="compact" />
            <IconButton iconName="edit" size="compact" />
            <IconButton
              disabled={currentVariant.default}
              className={styles.controlsButton}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                appendVariant(e, currentVariant)
              }
              iconName="content_copy"
              size="compact"
            />
          </Flex>
        </Flex>
      </Box>
      <Box height={256}>
        {hasVariants && currentVariant && !currentVariant.default && (
          <>
            <div>
              Render current variant here: name {currentVariant.name} uuid{' '}
              {currentVariant.uuid}
            </div>
          </>
        )}
        {hasVariants && currentVariant && currentVariant.default && (
          <DefaultContentBlock />
        )}
      </Box>
    </Box>
  );
};
