import { useQuery } from 'react-query';
import {
  fetchJourneyInsightsMetrics,
  fetchJourneyStepsInsightsMetrics,
  fetchJourneyStepUserActivity,
  JourneyInsightsMetricsData,
  JourneyStepsInsightsMetric,
  JourneyStepsInsightsMetricsData,
  UserJourneyActivity,
} from '../services/api-journey-metrics';

import { QueryResponse } from './common';
import { useProgram } from '../contexts/program';

export const useJourneyInsightsMetricsQuery = (props: {
  journeyId: string | number;
  activationId: string | number;
  stepId: string | number;
}): QueryResponse<JourneyInsightsMetricsData> => {
  const { id: programId } = useProgram();
  const { journeyId, activationId, stepId } = props;
  const { isLoading, error, data } = useQuery<
    { data: JourneyInsightsMetricsData },
    Error
  >(
    [
      'journey-insights-metrics',
      { programId, journeyId, activationId, stepId },
    ],
    () =>
      fetchJourneyInsightsMetrics({
        ...props,
        programId,
        journeyId,
        activationId: activationId ?? '',
        stepId,
      }),
    { retry: false, enabled: activationId !== undefined }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data,
  };
};

export const useJourneyStepsInsightsMetricsQuery = (props: {
  journeyId: string | number;
  activationId?: string | number;
  query: { [key: string]: unknown };
}): QueryResponse<{ [stepId: string]: JourneyStepsInsightsMetric }> => {
  const { id: programId } = useProgram();
  const { journeyId, activationId, query } = props;
  const { isLoading, error, data } = useQuery<
    { data: JourneyStepsInsightsMetricsData },
    Error
  >(
    ['journey-steps-insights-metrics', { programId, journeyId }],
    () =>
      fetchJourneyStepsInsightsMetrics({
        programId,
        journeyId,
        activationId: activationId ?? '',
        query,
      }),
    { retry: false, enabled: activationId !== undefined }
  );
  const mappedData: { [stepId: string]: JourneyStepsInsightsMetric } = {};
  const dataFields = [
    'uniqueUsersEntered',
    'uniqueUsersExited',
    'uniqueUsersCurrent',
    'uniqueUsersSent',
    'uniqueUsersDelivered',
    'uniqueUsersError',
    'uniqueUsersInteracted',
    'uniqueUsersOpened',
  ];
  data?.data.forEach((d) => {
    if (d.stepId) {
      if (mappedData[d.stepId] === undefined) {
        mappedData[d.stepId] = {
          stepId: d.stepId,
          uniqueUsersEntered: 0,
          uniqueUsersExited: 0,
          uniqueUsersCurrent: 0,
          uniqueUsersSent: 0,
          uniqueUsersDelivered: 0,
          uniqueUsersError: 0,
          uniqueUsersInteracted: 0,
          uniqueUsersOpened: 0,
        };
      }
      dataFields.forEach((field) => {
        mappedData[d.stepId][
          field as keyof JourneyStepsInsightsMetric
        ] += parseInt(
          ((d?.[
            field as keyof JourneyStepsInsightsMetric
          ] as unknown) as string) || '0',
          10
        );
      });
    }
  });
  return {
    isLoading,
    errorMessage: error?.message,
    data: mappedData,
  };
};

export const useJourneyStepsUserActivityQuery = (props: {
  journeyId: string | number;
  activationId?: string | number;
  stepId: string | number;
  query: { [key: string]: unknown };
}): QueryResponse<Array<UserJourneyActivity>> & {
  meta?: { totalRecords: number };
} => {
  const { id: programId } = useProgram();
  const { journeyId, stepId, activationId, query } = props;
  const { isLoading, error, data } = useQuery<
    { data: Array<UserJourneyActivity>; meta: { totalRecords: number } },
    Error
  >(
    [
      'journey-steps-insights-metrics',
      { programId, journeyId, activationId, stepId, query },
    ],
    () =>
      fetchJourneyStepUserActivity({
        programId,
        journeyId,
        activationId: activationId ?? '',
        stepId,
        query,
      }),
    { retry: false, enabled: activationId !== undefined }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    meta: data?.meta,
    data: data?.data,
  };
};
