import cx from 'classnames';
import { Button } from 'DesignSystem/Form/InputElements';
import { DateTime } from 'luxon';
import { Option } from 'models/insight/json/filterJson';
import React, { useCallback, useMemo, useState } from 'react';
import { ClickDropdown } from 'shared/ClickDropdown';
import { DashboardFilterContext } from '../contexts/DashboardFilterContext';
import styles from './dashboard-parameter.module.css';
import { DashboardFilterDropdown } from './DashboardFilterDropdown';
import { generateDateValues } from './filter-utils';

type DashboardParameterProps = {
  label: string;
  name: string;
  values: Option[];
  onChange: (name: string, value: string | number | boolean | Date) => void;
  updateFilter?: (value: string) => void;
  selected?: string | number | boolean | Date;
  clearDisabled?: boolean;
  queryHook?: () => void;
  valueTransform?: (data: {
    [key: string]: string;
  }) => { data: string; value: string };
};

const DashboardParameter: React.FC<DashboardParameterProps> = ({
  label,
  name,
  values,
  onChange,
  updateFilter,
  selected,
  clearDisabled = false,
}) => {
  const [selectedValue, setSelectedValues] = useState<
    string | number | boolean | Date | undefined
  >(selected);
  const onParameterChanged = useCallback(
    (value: string[]) => {
      if (value.length > 1) {
        const newVal = value[value.length - 1];
        setSelectedValues(newVal);
        updateFilter?.(newVal);
      } else {
        const [newVal] = value.length === 0 ? [] : value;
        setSelectedValues(newVal);
        updateFilter?.(newVal);
      }
    },
    [updateFilter]
  );

  const onClose = () => {
    if (selectedValue) onChange(name, selectedValue);
  };

  return (
    <DashboardFilterDropdown
      clearDisabled={clearDisabled}
      onChange={onParameterChanged}
      options={values}
      label={label}
      selectedOptions={
        values
          ? values.filter((opt: Option) => selectedValue === opt.value)
          : []
      }
      multiSelect={false}
      onClose={onClose}
    />
  );
};

const dateOptions = {
  quarter: generateDateValues('quarter'),
  month: generateDateValues('month'),
  year: generateDateValues('year'),
};

export const DateRangePickerContainer: React.FC<{
  onChange: (name: string, value: string[]) => void;
}> = ({ onChange }) => {
  const { appliedFilters } = React.useContext(DashboardFilterContext);

  const lastMonth = DateTime.now().startOf('month').minus({ months: 1 });
  const startDate = appliedFilters?.time_range_start_date?.value;
  const endDate = appliedFilters?.time_range_end_date?.value;
  const granularity = appliedFilters?.time_range_granularity_param?.value;

  const [selectedGranularity, setSelectedGranularity] = useState<string>(
    granularity ? granularity[0] : 'month'
  );
  const [selectedStartDate, setSelectedStartDate] = useState<{
    name: string;
    value: string;
  }>({
    name: 'time_range_start_date',
    value: startDate
      ? startDate[0]
      : lastMonth.minus({ months: 3 }).toLocaleString(DateTime.DATE_SHORT),
  });
  const [selectedEndDate, setSelectedEndDate] = useState<{
    name: string;
    value: string;
  }>({
    name: 'time_range_end_date',
    value: endDate ? endDate[0] : lastMonth.toLocaleString(DateTime.DATE_SHORT),
  });
  const [selectedDateOptions, setSelectedDateOptions] = useState<Option[]>(
    dateOptions[selectedGranularity as keyof typeof dateOptions]
  );

  const updateFilters = useCallback(
    (value: string) => {
      if (value === undefined) return;
      setSelectedGranularity(value);
      setSelectedDateOptions(dateOptions[value as keyof typeof dateOptions]);
      onChange('time_range_granularity_param', [value]);
    },
    [onChange]
  );

  const onParameterChanged = useCallback(
    (name: string, value: string) => {
      if (name === selectedStartDate.name) {
        setSelectedStartDate({ name, value });
      } else if (name === selectedEndDate.name) {
        setSelectedEndDate({ name, value });
      }
      if (value !== undefined) {
        onChange(name, [value]);
      }
    },
    [selectedStartDate.name, selectedEndDate.name, onChange]
  );

  const label = useMemo(() => {
    if (
      selectedStartDate.value &&
      selectedEndDate.value &&
      selectedDateOptions
    ) {
      const startLbl = selectedDateOptions.find(
        (o) => o.value === selectedStartDate.value
      )?.label;
      const endLbl = selectedDateOptions.find(
        (o) => o.value === selectedEndDate.value
      )?.label;
      if (startLbl && endLbl) {
        return `${endLbl} ${' vs.  '} ${startLbl}`;
      }
    }

    return '';
  }, [selectedStartDate.value, selectedEndDate.value, selectedDateOptions]);

  const reset = useCallback(() => {
    const currentGranularity = selectedGranularity ?? 'month';
    setSelectedGranularity(currentGranularity);
    setSelectedDateOptions(
      dateOptions[currentGranularity as keyof typeof dateOptions]
    );
    setSelectedStartDate({
      name: 'time_range_start_date',
      value: startDate
        ? startDate[0]
        : lastMonth.minus({ months: 3 }).toLocaleString(DateTime.DATE_SHORT),
    });
    setSelectedEndDate({
      name: 'time_range_end_date',
      value: endDate
        ? endDate[0]
        : lastMonth.toLocaleString(DateTime.DATE_SHORT),
    });
  }, [endDate, lastMonth, selectedGranularity, startDate]);

  const done = useCallback(
    (close: () => void) => {
      onChange('time_range_granularity_param', [selectedGranularity as string]);
      onChange(selectedStartDate.name, [selectedStartDate.value as string]);
      onChange(selectedEndDate.name, [selectedEndDate.value as string]);
      close();
    },
    [onChange, selectedGranularity, selectedStartDate, selectedEndDate]
  );

  const dropdown = React.useCallback(
    (close: () => void) => {
      const granularityOptions = [
        { label: 'Quarterly', value: 'quarter' },
        { label: 'Yearly', value: 'year' },
        { label: 'Monthly', value: 'month' },
      ];
      return (
        <div className={styles.dropdown}>
          <div className={styles.dropdownRow}>
            <DashboardParameter
              label="Granularity"
              name="time_range_granularity_param"
              values={granularityOptions}
              selected={selectedGranularity}
              onChange={() => {}}
              updateFilter={updateFilters}
              clearDisabled
            />
          </div>
          <div className={styles.dropdownRow}>
            <DashboardParameter
              label="End Date"
              name={selectedEndDate.name}
              values={selectedDateOptions}
              selected={selectedEndDate.value}
              onChange={() => {}}
              updateFilter={(value: string) => {
                onParameterChanged(selectedEndDate.name, value);
              }}
            />
            <span className={styles.delimiter}>{' vs.  '}</span>
            <DashboardParameter
              label="Start Date"
              name={selectedStartDate.name}
              values={selectedDateOptions}
              selected={selectedStartDate.value}
              onChange={() => {}}
              updateFilter={(value: string) => {
                onParameterChanged(selectedStartDate.name, value);
              }}
            />
          </div>
          <div className={styles.dropdownRow}>
            <Button
              borderless
              className={styles.buttonWhite}
              onClick={() => reset()}
              label="Reset"
              compact
            />
            <Button
              borderless
              className={styles.done}
              label="Done"
              onClick={() => done(close)}
              compact
            />
          </div>
        </div>
      );
    },
    [
      selectedGranularity,
      updateFilters,
      selectedEndDate.name,
      selectedEndDate.value,
      selectedDateOptions,
      selectedStartDate.name,
      selectedStartDate.value,
      onParameterChanged,
      reset,
      done,
    ]
  );
  return (
    <ClickDropdown dropdownRenderProp={dropdown}>
      <div
        className={cx(styles.container, 'kai-flex-row', {
          [styles.filterApplied]: label.length > 0,
        })}
      >
        <span className={styles.label}>{`Time Range: ${label}`}</span>
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.append}
        >
          <path
            d="M0.88916 1.7778L7.00027 6.22225L13.1114 1.7778"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </ClickDropdown>
  );
};
