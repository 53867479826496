import * as React from 'react';
import { CSSProperties } from 'react';
import cx from 'classnames';

export type IconNames =
  | 'account_tree'
  | 'add'
  | 'add_link'
  | 'add_box'
  | 'align_horizontal_center'
  | 'align_horizontal_left'
  | 'align_horizontal_right'
  | 'alternate_email'
  | 'arrow_left'
  | 'arrow_right'
  | 'arrow_upward_alt'
  | 'arrow_downward_alt'
  | 'article'
  | 'attachment'
  | 'bookmark'
  | 'border_color'
  | 'calendar_month'
  | 'calendar_today'
  | 'cancel'
  | 'check_circle'
  | 'chevron_left'
  | 'chevron_right'
  | 'close'
  | 'code'
  | 'content_copy'
  | 'content_paste'
  | 'crop_7_5'
  | 'dashboard'
  | 'data_object'
  | 'delete'
  | 'drag_indicator'
  | 'download'
  | 'edit'
  | 'edit_square'
  | 'expand'
  | 'expand_more'
  | 'expand_less'
  | 'filter_list'
  | 'flag'
  | 'format_align_center'
  | 'format_align_justify'
  | 'format_align_left'
  | 'format_align_right'
  | 'format_bold'
  | 'format_clear'
  | 'format_color_text'
  | 'format_h1'
  | 'format_h2'
  | 'format_h3'
  | 'format_h4'
  | 'format_h5'
  | 'format_h6'
  | 'format_indent_decrease'
  | 'format_indent_increase'
  // | 'format_ink_highlighter'
  | 'format_italic'
  | 'format_list_bulleted'
  | 'format_list_numbered'
  | 'format_paragraph'
  | 'format_quote'
  | 'format_size'
  | 'format_strikethrough'
  | 'format_underlined'
  | 'help'
  | 'home'
  | 'horizontal_rule'
  | 'insert_chart'
  | 'info'
  | 'laptop_windows'
  | 'lock'
  | 'lightbulb'
  | 'mail'
  | 'more_vert'
  | 'notifications'
  | 'numbers'
  | 'open_in_new'
  | 'palette'
  | 'pending'
  | 'photo'
  | 'phone_iphone'
  | 'photo_library'
  | 'post_add'
  | 'redo'
  | 'refresh'
  | 'save'
  | 'save_as'
  | 'schedule'
  | 'settings'
  | 'share'
  | 'slideshow'
  | 'subscript'
  | 'subtitles'
  | 'superscript'
  | 'swap_vert'
  | 'sync'
  | 'table'
  | 'text_fields'
  | 'text_format'
  | 'touch_app'
  | 'undo'
  | 'upload'
  | 'vertical_align_top'
  | 'vertical_align_bottom'
  | 'vertical_align_center'
  | 'view_carousel'
  | 'visibility'
  | 'visibility_off'
  | 'warning';

export const MAIcon: React.FC<{
  name: IconNames;
  className?: string;
  style?: CSSProperties;
}> = ({ name, className, style }) => (
  <span
    className={cx('material-symbols-rounded', name, className)}
    style={style}
  >
    {name}
  </span>
);
