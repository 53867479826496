import * as React from 'react';
import cx from 'classnames';
import classNames from './typography.module.css';

export const color = {
  gray00: classNames.ColorGray00,
  gray02: classNames.ColorGray02,
  gray05: classNames.ColorGray05,
  gray10: classNames.ColorGray10,
  gray20: classNames.ColorGray20,
  gray30: classNames.ColorGray30,
  gray40: classNames.ColorGray40,
  gray50: classNames.ColorGray50,
  gray60: classNames.ColorGray60,
  gray70: classNames.ColorGray70,
  gray80: classNames.ColorGray80,
  gray90: classNames.ColorGray90,
  brandTintLight: classNames.ColorBrandTintLight,
  brandTintDark: classNames.ColorBrandTintDark,
  brandFull: classNames.ColorBrandFull,
  brandShadeLight: classNames.ColorBrandShadeLight,
  brandShadeDark: classNames.ColorBrandShadeDark,
  brandSolar: classNames.ColorBrandSolar,
  greenTintLight: classNames.ColorGreenTintLight,
  greenTintDark: classNames.ColorGreenTintDark,
  greenFull: classNames.ColorGreenFull,
  greenShadeLight: classNames.ColorGreenShadeLight,
  greenShadeDark: classNames.ColorGreenShadeDark,
  redTintLight: classNames.ColorRedTintLight,
  redTintDark: classNames.ColorRedTintDark,
  redFull: classNames.ColorRedFull,
  redShadeLight: classNames.ColorRedShadeLight,
  redShadeDark: classNames.ColorRedShadeDark,
  orangeTintLight: classNames.ColorOrangeTintLight,
  orangeTintDark: classNames.ColorOrangeTintDark,
  orangeFull: classNames.ColorOrangeFull,
  orangeShadeLight: classNames.ColorOrangeShadeLight,
  orangeShadeDark: classNames.ColorOrangeShadeDark,
  yellowTintLight: classNames.ColorYellowTintLight,
  yellowTintDark: classNames.ColorYellowTintDark,
  yellowFull: classNames.ColorYellowFull,
  yellowShadeLight: classNames.ColorYellowShadeLight,
  yellowShadeDark: classNames.ColorYellowShadeDark,
  tealTintLight: classNames.ColorTealTintLight,
  tealTintDark: classNames.ColorTealTintDark,
  tealFull: classNames.ColorTealFull,
  tealShadeLight: classNames.ColorTealShadeLight,
  tealShadeDark: classNames.ColorTealShadeDark,
  blueTintLight: classNames.ColorBlueTintLight,
  blueTintDark: classNames.ColorBlueTintDark,
  blueFull: classNames.ColorBlueFull,
  blueShadeLight: classNames.ColorBlueShadeLight,
  blueShadeDark: classNames.ColorBlueShadeDark,
} as const;

export const background = {
  gray00: classNames.BackgroundGray00,
  gray02: classNames.BackgroundGray02,
  gray05: classNames.BackgroundGray05,
  gray10: classNames.BackgroundGray10,
  gray20: classNames.BackgroundGray20,
  gray30: classNames.BackgroundGray30,
  gray40: classNames.BackgroundGray40,
  gray50: classNames.BackgroundGray50,
  gray60: classNames.BackgroundGray60,
  gray70: classNames.BackgroundGray70,
  gray80: classNames.BackgroundGray80,
  gray90: classNames.BackgroundGray90,
  brandTintLight: classNames.BackgroundBrandTintLight,
  brandTintDark: classNames.BackgroundBrandTintDark,
  brandFull: classNames.BackgroundBrandFull,
  brandOutline: classNames.BackgroundBrandOutline,
  brandShadeLight: classNames.BackgroundBrandShadeLight,
  brandShadeDark: classNames.BackgroundBrandShadeDark,
  brandSolar: classNames.BackgroundBrandSolar,
  grayTintLight: classNames.BackgroundGrayTintLight,
  grayTintDark: classNames.BackgroundGrayTintDark,
  greenTintLight: classNames.BackgroundGreenTintLight,
  greenTintDark: classNames.BackgroundGreenTintDark,
  greenFull: classNames.BackgroundGreenFull,
  greenShadeLight: classNames.BackgroundGreenShadeLight,
  greenShadeDark: classNames.BackgroundGreenShadeDark,
  redTintLight: classNames.BackgroundRedTintLight,
  redTintDark: classNames.BackgroundRedTintDark,
  redFull: classNames.BackgroundRedFull,
  redShadeLight: classNames.BackgroundRedShadeLight,
  redShadeDark: classNames.BackgroundRedShadeDark,
  orangeTintLight: classNames.BackgroundOrangeTintLight,
  orangeTintDark: classNames.BackgroundOrangeTintDark,
  orangeFull: classNames.BackgroundOrangeFull,
  orangeShadeLight: classNames.BackgroundOrangeShadeLight,
  orangeShadeDark: classNames.BackgroundOrangeShadeDark,
  yellowTintLight: classNames.BackgroundYellowTintLight,
  yellowTintDark: classNames.BackgroundYellowTintDark,
  yellowFull: classNames.BackgroundYellowFull,
  yellowShadeLight: classNames.BackgroundYellowShadeLight,
  yellowShadeDark: classNames.BackgroundYellowShadeDark,
  tealTintLight: classNames.BackgroundTealTintLight,
  tealTintDark: classNames.BackgroundTealTintDark,
  tealFull: classNames.BackgroundTealFull,
  tealShadeLight: classNames.BackgroundTealShadeLight,
  tealShadeDark: classNames.BackgroundTealShadeDark,
  blueTintLight: classNames.BackgroundBlueTintLight,
  blueTintDark: classNames.BackgroundBlueTintDark,
  blueFull: classNames.BackgroundBlueFull,
  blueShadeLight: classNames.BackgroundBlueShadeLight,
  blueShadeDark: classNames.BackgroundBlueShadeDark,
  transparent: classNames.BackgroundTransparent,
} as const;

export const border = {
  gray00: classNames.BorderGray00,
  gray02: classNames.BorderGray02,
  gray05: classNames.BorderGray05,
  gray10: classNames.BorderGray10,
  gray20: classNames.BorderGray20,
  gray30: classNames.BorderGray30,
  gray40: classNames.BorderGray40,
  gray50: classNames.BorderGray50,
  gray60: classNames.BorderGray60,
  gray70: classNames.BorderGray70,
  gray80: classNames.BorderGray80,
  gray90: classNames.BorderGray90,
  brandTintLight: classNames.BorderBrandTintLight,
  brandTintDark: classNames.BorderBrandTintDark,
  brandFull: classNames.BorderBrandFull,
  brandShadeLight: classNames.BorderBrandShadeLight,
  brandShadeDark: classNames.BorderBrandShadeDark,
  brandSolar: classNames.BorderBrandSolar,
  brandOutline: classNames.BorderBrandOutline,
  greenTintLight: classNames.BorderGreenTintLight,
  greenTintDark: classNames.BorderGreenTintDark,
  greenFull: classNames.BorderGreenFull,
  greenShadeLight: classNames.BorderGreenShadeLight,
  greenShadeDark: classNames.BorderGreenShadeDark,
  redTintLight: classNames.BorderRedTintLight,
  redTintDark: classNames.BorderRedTintDark,
  redFull: classNames.BorderRedFull,
  redShadeLight: classNames.BorderRedShadeLight,
  redShadeDark: classNames.BorderRedShadeDark,
  orangeTintLight: classNames.BorderOrangeTintLight,
  orangeTintDark: classNames.BorderOrangeTintDark,
  orangeFull: classNames.BorderOrangeFull,
  orangeShadeLight: classNames.BorderOrangeShadeLight,
  orangeShadeDark: classNames.BorderOrangeShadeDark,
  yellowTintLight: classNames.BorderYellowTintLight,
  yellowTintDark: classNames.BorderYellowTintDark,
  yellowFull: classNames.BorderYellowFull,
  yellowShadeLight: classNames.BorderYellowShadeLight,
  yellowShadeDark: classNames.BorderYellowShadeDark,
  tealTintLight: classNames.BorderTealTintLight,
  tealTintDark: classNames.BorderTealTintDark,
  tealFull: classNames.BorderTealFull,
  tealShadeLight: classNames.BorderTealShadeLight,
  tealShadeDark: classNames.BorderTealShadeDark,
  blueTintLight: classNames.BorderBlueTintLight,
  blueTintDark: classNames.BorderBlueTintDark,
  blueFull: classNames.BorderBlueFull,
  blueShadeLight: classNames.BorderBlueShadeLight,
  blueShadeDark: classNames.BorderBlueShadeDark,
  width0: classNames.BorderWidthNone,
  width1: classNames.BorderWidthOne,
  width2: classNames.BorderWidthTwo,
  width4: classNames.BorderWidthFour,
  solid: classNames.BorderStyleSolid,
  dashed: classNames.BorderStyleDashed,
  radius16: classNames.BorderRadiusSixteen,
  radius8: classNames.BorderRadiusEight,
  radius4: classNames.BorderRadiusFour,
  radius2: classNames.BorderRadiusTwo,
} as const;

export type Background = typeof background[keyof typeof background];
export type Color = typeof color[keyof typeof color];
export type Border = typeof border[keyof typeof border];

// The exported Components at the bottom of the
// file all render spans with some classNames.
// If it is important _not_ to have that additional
// DOM node, these classNames are exported as well
// so the styles themselves can be reused.
export const styles = {
  PageHeading: classNames.PageHeading,
  Heading: classNames.Heading,
  Subheading: classNames.Subheading,
  Body: classNames.Body,
  Caption: classNames.Caption,
  Bold: classNames.Bold,
  Semibold: classNames.Semibold,
  Italic: classNames.Italic,
  Block: classNames.Block,
  Underline: classNames.Underline,
  Nobr: classNames.Nobr,
  Uppercase: classNames.Uppercase,
  Text: classNames.Text,
  FieldLabel: classNames.FieldLabel,
} as const;

// The variations of plain text
type Props = {
  bold?: boolean;
  semibold?: boolean;
  block?: boolean;
  underline?: boolean;
  nobr?: boolean;
  uppercase?: boolean;
  italic?: boolean;
  color?: typeof color[keyof typeof color];
  id?: string;
};

// a derivative of cx, heh, for applying variations
function dx(className: string, props: Props) {
  return cx(className, {
    [styles.Bold]: props.bold,
    [styles.Semibold]: props.semibold,
    [styles.Block]: props.block,
    [styles.Underline]: props.underline,
    [styles.Nobr]: props.nobr,
    [styles.Uppercase]: props.uppercase,
    [styles.Italic]: props.italic,
    [props.color ?? '']: props.color,
  });
}

// generic wrapper to Span for brevity in the exported components.
export const Span: (
  className: string,
  defaultProps?: Partial<Props>
) => React.FC<Props> = (className, defaultProps = {}) => ({
  children,
  id,
  ...props
}) => (
  <span className={dx(className, { ...defaultProps, ...props })} id={id}>
    {children}
  </span>
);

// General-use components
export const Body = Span(styles.Body);
export const Caption = Span(styles.Caption, { color: color.gray60 });

export const ItalicCaption = Span(styles.Caption, {
  color: color.gray60,
  italic: true,
});

export const Subheading = Span(styles.Subheading);
export const Heading = Span(styles.Heading);
export const PageHeading = Span(styles.PageHeading, {
  bold: true,
  block: true,
});
export const FieldLabel = Span(styles.FieldLabel);

type StyleConstants = typeof styles & typeof color;
type TextCssClassNames = {
  [K in keyof StyleConstants]?: boolean;
};

const getCssNameFromObject = <T extends StyleConstants>(
  key: keyof T,
  object: T
) => object[key];

type TextProps<C extends React.ElementType> = {
  as?: C;
  className: TextCssClassNames;
};

type TextPropsWithElementProps<
  C extends React.ElementType
> = React.PropsWithChildren<TextProps<C>> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof TextProps<C>>;

export const Text = <ComponentType extends React.ElementType = 'span'>({
  as,
  children,
  className,
  ...restProps
}: TextPropsWithElementProps<ComponentType>): JSX.Element => {
  const Component = as || 'span';

  const classNameKeys = Object.keys(className) as Array<
    keyof TextCssClassNames
  >;
  const cssClassNames = classNameKeys.reduce((prev, curr) => {
    const key = getCssNameFromObject(curr, { ...styles, ...color });
    return { ...prev, [key]: true };
  }, {});

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Component className={cx(cssClassNames)} {...restProps}>
      {children}
    </Component>
  );
};

Text.defaultProps = {
  as: 'span',
};
