import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import { RouteComponentProps } from '@reach/router';
import { Helmet } from 'react-helmet';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Switcher } from 'components/publisher/previews/Switcher';
import { PreviewContext } from 'contexts/publisher/compose/preview';
import { useProgram } from 'contexts/program';
import { useUser } from 'contexts/user';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { usePersonalizedFieldsFilesQuery } from 'hooks/personalized-fields';
import { hasUnsafeWebFonts } from 'models/publisher/style';
import { blockHasPersonalizationVariables } from 'models/publisher/block';
import * as DevMode from 'DevMode';
import { EmailPreview } from 'DevMode/EmailPreview';
import { SendTestCampaign } from 'App/Program/Editors/Publisher/NewReview/ContentPreviewSection/SendTestCampaign';
import { usePermissions } from 'contexts/permissions';
import { usePublisher } from 'contexts/publisher';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from '../../theme/compose.module.css';
import { VariablePreviewOptions } from './VariablePreviewOptions';
import { FontPreviewOptions } from './FontPreviewOptions';
import { PersonalizedFieldVariablePreviewOptions } from './personalized-field/PersonalizedFieldVariablePreviewOptions';
import { NewVariablePreviewOptions } from './personalized-field/NewVariablePreviewOptions';

export const Preview: React.FC<
  RouteComponentProps & { hideEmailPreview?: boolean }
> = ({ hideEmailPreview }) => {
  const { id } = useProgram();
  const { id: userId } = useUser();
  const { data: newEditorsFlag } = useFeatureFlagsQuery(
    id,
    'Studio.Publish.NewEditors'
  );
  const { data: personalizedFieldsFlag } = useFeatureFlagsQuery(
    id,
    'Studio.Publish.PersonalizedFields'
  );
  const { data: personalizedFieldsFlagV2 } = useFeatureFlagsQuery(
    id,
    'Studio.Publish.PersonalizedFieldsV2'
  );
  const { data: variablesFlag } = useFeatureFlagsQuery(
    id,
    'Studio.LiquidVariables'
  );
  const {
    permissions: { configureSetPersonalizedFields },
  } = usePermissions();
  const publisher = usePublisher();
  const {
    tab,
    tabs,
    html,
    isLoading,
    setTab,
    webFontsEnabled,
  } = React.useContext(PreviewContext);

  const { data: pfData } = usePersonalizedFieldsFilesQuery(
    publisher.id === 'new' ? -1 : publisher.id,
    (personalizedFieldsFlag?.value as boolean) || false
  );

  const hasPfFile = publisher?.post?.content?.hasPfFile;

  const hasMyselfAsCreator =
    pfData?.some((file) => file.creatorId === userId) || false;

  const showVariablesPreview = React.useMemo<boolean>(() => {
    return (
      !!variablesFlag?.value &&
      publisher.post.blocks.some(blockHasPersonalizationVariables)
    );
  }, [publisher.post.blocks, variablesFlag?.value]);

  const showFallbackFontPreview =
    hasUnsafeWebFonts(publisher.post.styles.fonts) ||
    publisher.post.blocks.some(
      (block) =>
        block.style_data?.fonts &&
        hasUnsafeWebFonts(block.style_data.fonts as Record<string, string>)
    );

  const showPersonalizedFieldsPreview =
    personalizedFieldsFlag?.value &&
    configureSetPersonalizedFields &&
    hasMyselfAsCreator;

  const iframe = useRef<HTMLIFrameElement>(null);

  const [height, setHeight] = useState(2000);

  const onLoad = useCallback(() => {
    const scrollHeight = iframe.current?.contentDocument?.body?.scrollHeight;
    if (scrollHeight) setHeight(scrollHeight);
  }, []);

  const showPreview =
    showVariablesPreview ||
    showPersonalizedFieldsPreview ||
    showFallbackFontPreview;

  const showNewPreview = personalizedFieldsFlagV2?.value;

  const renderNewVariablePreview = () => {
    if (showPreview && showNewPreview) {
      return (
        <>
          <NewVariablePreviewOptions
            hasPfFile={hasPfFile}
            hasMyselfAsCreator={hasMyselfAsCreator}
          />
          <br />
        </>
      );
    }

    if (showPersonalizedFieldsPreview) {
      return <PersonalizedFieldVariablePreviewOptions />;
    }

    return <VariablePreviewOptions />;
  };

  if (!newEditorsFlag) return null; // wait until we know which editor to use...
  return (
    <>
      <Helmet>
        <title>Preview</title>
      </Helmet>

      <div className={styles.container}>
        <div className={styles.sidebar}>
          <div
            className={cx(styles.sectionContainer, {
              [styles.sidebarMarginOld]: !newEditorsFlag.value,
              [styles.sidebarMargin]: newEditorsFlag.value,
            })}
          >
            <strong data-testid="preview-title" className={styles.title}>
              Preview
            </strong>
          </div>
          {renderNewVariablePreview()}
          {showPreview && !showNewPreview && <br />}
          <FontPreviewOptions />
          <EmailPreview
            delivery={tab?.delivery}
            webFontsEnabled={webFontsEnabled}
          />
          {/* Hide if we are previewing a template */}
          <div className={showPreview ? styles.sendTestCampaign : ''}>
            {!hideEmailPreview && <SendTestCampaign />}
          </div>
        </div>
        <div className={cx(styles.main, styles.transparent)}>
          <div>
            <Flex center>
              {tab && (
                <tab.frame post={publisher.post}>
                  <DevMode.CopyToClipboard name="HTML" value={html} />
                  {isLoading ? (
                    <div
                      className="flex-centered kai-flex-row"
                      style={{
                        margin: '100px auto',
                      }}
                    >
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <iframe
                      title="Preview"
                      srcDoc={html}
                      onLoad={onLoad}
                      ref={iframe}
                      style={{
                        width: '100%',
                        height: `${height}px`,
                        minHeight: `${
                          tab?.delivery !== 'email' ? '100%' : 'auto'
                        }`,
                      }}
                    />
                  )}
                </tab.frame>
              )}
              {tab && tabs && (
                <Switcher tab={tab} tabs={tabs} setTab={setTab} />
              )}
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
};
