import {
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { Permissions } from 'models/permissions';
import { AdminPreferences, User } from 'models/user';
import {
  fetchCurrentUser,
  fetchCurrentUserPermissions,
  fetchUsers,
  fetchUser,
  UserData,
  FetchProps,
  UserCollectionData,
  forgetUsers,
  activateUsers,
  deactivateUsers,
  inviteUsers,
  generatePasswordResetLink,
  hideUser,
  exportUsers,
  confirmUser,
  bulkExportUsers,
  activateUser,
  deactivateUser,
  forgetUser,
  updateUser,
  hideUsers,
  importUsersFromFile,
  NewUserData,
  createUser,
  fetchByIds,
  updateAdminPreferences,
  unHideUser,
  fetchUserMemberships,
  fetchUserAudiences,
} from 'services/api-user';
import {
  AudienceMembershipData,
  AudienceReadData,
  UserExportData,
} from 'services/api-audiences';
import { BulkUploadJob } from 'models/bulk-upload-job';
import {
  nextPageToFetch,
  BulkSelection,
  QueryResponse,
  InfiniteQueryResponse,
  MutationOptions,
  UserBulkActionFilters,
} from './common';
import { useProgram } from '../contexts/program';
import { getActor } from '../services/global-actor-storage';

// No data transformation is needed (but might be in the future)
const mapDataToUser = (data: UserData): User => data as User;

export const useUsersQuery = (
  programId: number,
  page: number,
  search = '',
  audienceQuery = false
): QueryResponse<Array<User>> => {
  const { isLoading, error, data } = useQuery<UserCollectionData, Error>(
    ['Users', programId, page, search], // Cache key, must be distinct for different query params
    () => fetchUsers({ programId, page, search, audienceQuery }),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data || [],
  };
};

export const useUserByIdsQuery = (
  programId: number,
  ids: number[],
  queryOptions: UseQueryOptions<UserCollectionData, Error> = {}
): QueryResponse<Array<User>> => {
  const queryClient = useQueryClient();
  const { isLoading, error, data } = useQuery<UserCollectionData, Error>(
    ['Users', programId, ids], // Cache key, must be distinct for different query params
    async () => {
      const users = await fetchByIds(ids, programId);
      users.data.forEach((user) => {
        // push data to cache for `useUserQuery`
        queryClient.setQueryData<User>(`User-${user.id}`, {
          ...user,
          name: `${user.firstName} ${user.lastName}`,
        });
      });
      return users;
    },
    {
      retry: false,
      ...queryOptions,
    }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.data || [],
  };
};

export const useUserQuery = (
  programId: number,
  userId: number
): QueryResponse<User> => {
  const { isLoading, error, data } = useQuery<UserData, Error>(
    `User-${userId}`,
    () => fetchUser(programId, userId),
    { retry: false, staleTime: 1000 * 60 * 120 } // 120 minutes
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapDataToUser(data),
  };
};

export const useUsersInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<User> => {
  const {
    programId,
    pageSize = 20,
    search,
    audienceQuery,
    query,
    filters,
    statuses,
    roles,
    scopes,
    audiences,
  } = props;
  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<UserCollectionData, Error>(
    ['users-infinite', JSON.stringify(props)],
    async ({ pageParam = 1 }) =>
      fetchUsers({
        programId,
        page: pageParam,
        pageSize,
        search,
        scopes,
        query,
        filters,
        audienceQuery,
        statuses,
        roles,
        audiences,
      }),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup && nextPageToFetch(lastGroup.meta, pageSize),
    }
  );

  const batchData = data?.pages.map((batch) => (batch ? batch.data : []));

  const flatData = batchData && batchData.flat(1);

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
    meta: data?.pages[0].meta,
  };
};

type CurrentUserQueryProps = {
  refetchInterval?: number | false;
  soft?: boolean; // allow the user fetch to fail quietly, without breaking the app's auth state
};

// Retrieve the currently authenticated user (if any)
export const useCurrentUserQuery = (
  options?: CurrentUserQueryProps
): QueryResponse<User> => {
  const { isLoading, error, data } = useQuery<UserData | undefined, Error>(
    ['current-user', `${options?.soft ? '-soft' : ''}`],
    fetchCurrentUser,
    { retry: false, refetchInterval: options?.refetchInterval }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data && mapDataToUser(data),
  };
};

// Retrieve the currently authenticated user (if any)
export const useCurrentUserPermissionsQuery = (
  programId: number
): QueryResponse<Permissions> => {
  const { isLoading, error, data } = useQuery<Permissions | undefined, Error>(
    `current_user_permissions-${programId}`,
    () => fetchCurrentUserPermissions(programId),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useCreateUser = (
  programId: number,
  { onError, onSuccess }: MutationOptions<User, string> = {}
): { create: (data: NewUserData) => void } => {
  const create = (data: NewUserData) => {
    createUser(programId, data)
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { create };
};

export const useUpdateAdminPreferences = ({
  onError,
  onSuccess,
}: MutationOptions<AdminPreferences> = {}): {
  update: (data: AdminPreferences) => void;
} => {
  const update = (data: AdminPreferences) => {
    updateAdminPreferences(data)
      .then((d) => {
        if (onSuccess) onSuccess(d);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { update };
};

export const useUserBulkForget = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  forget: (
    bulkSelection: BulkSelection,
    filterConfig: UserBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const forget = (
    bulkSelection: BulkSelection,
    filterConfig?: UserBulkActionFilters
  ) => {
    const request = forgetUsers(programId, bulkSelection, filterConfig);

    request
      .then(() => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return {
    forget,
  };
};

export const useUserBulkActivate = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  activate: (
    bulkSelection: BulkSelection,
    filterConfig?: UserBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const activate = (
    bulkSelection: BulkSelection,
    filterConfig: UserBulkActionFilters
  ) => {
    const request = activateUsers(programId, bulkSelection, filterConfig);

    request
      .then(() => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return {
    activate,
  };
};

export const useUserBulkDeactivate = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  deactivate: (
    bulkSelection: BulkSelection,
    filterConfig?: UserBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const deactivate = (
    bulkSelection: BulkSelection,
    filterConfig: UserBulkActionFilters
  ) => {
    const request = deactivateUsers(programId, bulkSelection, filterConfig);

    request
      .then(() => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return {
    deactivate,
  };
};

export const useInviteUsers = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  invite: (
    ids: string[] | undefined,
    excludedIds: string[] | undefined,
    sendStudioEmail: string,
    sendExperienceEmail: string,
    query?: string,
    audiences?: string[],
    scopes?: string[]
  ) => void;
} => {
  const queryClient = useQueryClient();
  const invite = (
    ids: string[] | undefined,
    excludedIds: string[] | undefined,
    sendStudioEmail: string,
    sendExperienceEmail: string,
    query?: string,
    audiences?: string[],
    scopes?: string[]
  ) => {
    const request = inviteUsers(
      programId,
      ids,
      excludedIds,
      sendStudioEmail,
      sendExperienceEmail,
      query,
      audiences,
      scopes
    );

    request
      .then(() => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return {
    invite,
  };
};

export const useActivateUser = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  activate: (id: number) => void;
} => {
  const queryClient = useQueryClient();
  const activate = (id: number) => {
    const request = activateUser(programId, id);
    request
      .then(() => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { activate };
};

export const useDeactivateUser = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  deactivate: (id: number) => void;
} => {
  const queryClient = useQueryClient();
  const deactivate = (id: number) => {
    const request = deactivateUser(programId, id);
    request
      .then(() => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { deactivate };
};

export const useForgetUser = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  forget: (id: number) => void;
} => {
  const queryClient = useQueryClient();
  const forget = (id: number) => {
    const request = forgetUser(programId, id);
    request
      .then(() => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { forget };
};

export const useResetPassword = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  resetPassword: (id: number) => void;
} => {
  const queryClient = useQueryClient();
  const resetPassword = (id: number) => {
    const request = generatePasswordResetLink(programId, id);
    request
      .then((response) => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess(response.url);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { resetPassword };
};

export const useHideUser = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  hide: (id: number) => void;
  unHide: (id: number) => void;
} => {
  const queryClient = useQueryClient();
  const hide = (id: number) => {
    const request = hideUser(programId, id);
    request
      .then(() => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess('User hidden.');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  const unHide = (id: number) => {
    const request = unHideUser(programId, id);
    request
      .then(() => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess('User unhidden.');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { hide, unHide };
};

export const useExportUsers = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  doExport: (ids: number[]) => void;
} => {
  const queryClient = useQueryClient();
  const doExport = (ids: number[]) => {
    const request = exportUsers(programId, ids);
    request
      .then((response) => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess(response.data.id.toString());
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { doExport };
};

export const useBulkExportUsers = (
  programId: number,
  onSuccess: (UserExportData: UserExportData, selectedCount: number) => void,
  { onError }: MutationOptions<UserExportData> = {}
): {
  bulkExport: (
    ids: string[] | undefined,
    excludedIds: string[] | undefined,
    query?: string,
    statuses?: string | string[],
    roles?: string | string[],
    totalRecords?: number,
    audiences?: string[],
    scopes?: string[]
  ) => void;
} => {
  const queryClient = useQueryClient();
  const bulkExport = (
    ids: string[] | undefined,
    excludedIds: string[] | undefined,
    query?: string,
    statuses?: string | string[],
    roles?: string | string[],
    totalRecords?: number,
    audiences?: string[],
    scopes?: string[]
  ) => {
    const request = bulkExportUsers(
      programId,
      ids,
      excludedIds,
      query,
      statuses,
      roles,
      audiences,
      scopes
    );
    let selectedCount = 0;
    if (ids !== undefined) selectedCount = ids.length;
    else if (totalRecords && excludedIds)
      selectedCount = totalRecords - excludedIds.length;
    request
      .then((res) => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess(res.data, selectedCount);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return {
    bulkExport,
  };
};

export const useConfirmUser = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  confirm: (userId: number) => void;
} => {
  const queryClient = useQueryClient();
  const confirm = (userId: number) => {
    const request = confirmUser(programId, userId);
    request
      .then(() => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { confirm };
};

export const useUpdateUser = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  update: (userId: number, data: User) => void;
} => {
  const update = (userId: number, data: User) => {
    updateUser(programId, userId, data)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { update };
};

export const useUserBulkHide = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string> = {}
): {
  hide: (
    bulkSelection: BulkSelection,
    filterConfig?: UserBulkActionFilters
  ) => void;
} => {
  const queryClient = useQueryClient();
  const hide = (
    bulkSelection: BulkSelection,
    filterConfig: UserBulkActionFilters
  ) => {
    const request = hideUsers(programId, bulkSelection, filterConfig);

    request
      .then(() => {
        queryClient.invalidateQueries(['users-infinite']);
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return {
    hide,
  };
};

export const useImportUsers = (
  programId: number,
  onSuccess?: (job: BulkUploadJob) => void,
  onError?: (message: string) => void
): { importUsers: (file: File) => void } => {
  const importUsers = (file: File) => {
    const request = importUsersFromFile(programId, file);
    request
      .then((data) => {
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };

  return { importUsers };
};

/**
 * Fetches the audiences that the current user is a member of.
 */
export const useCurrentUserAudiencesQuery = (
  options?: UseQueryOptions<AudienceReadData[] | undefined>
): UseQueryResult<AudienceReadData[] | undefined> => {
  const { id: programId } = useProgram();
  const userId = getActor()?.userId;
  return useQuery(
    ['programs', programId, 'users', userId, 'audiences'],
    () => (userId ? fetchUserAudiences(programId, userId) : undefined),
    { retry: false, ...options, enabled: !!userId }
  );
};
export const useCurrentUserMembershipsQuery = (
  options?: UseQueryOptions<AudienceMembershipData[] | undefined>
): UseQueryResult<AudienceMembershipData[] | undefined> => {
  const { id: programId } = useProgram();
  const userId = getActor()?.userId;
  return useQuery(
    ['programs', programId, 'users', userId, 'memberships'],
    () => (userId ? fetchUserMemberships(programId, userId) : undefined),
    { retry: false, ...options, enabled: !!userId }
  );
};
