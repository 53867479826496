import camelcaseKeys from 'camelcase-keys';
import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export type TableauDashboardFeature = {
  id: number;
  dashboardId: string;
  key: string;
  metaData: string;
};
export type TableauDashboardFeaturesData = Array<TableauDashboardFeature>;
export const fetchTableauDashboardFeatures = async (props: {
  programId: number;
  dashboardId: string;
}): Promise<{ data: TableauDashboardFeaturesData }> => {
  const { programId, dashboardId } = props;
  const url = `${apiRoot}/api/v1/programs/${programId}/insights/dashboard/${dashboardId}/features`;

  const response = await request(url);
  if (response.status === 200) {
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));
  }
  throw new Error(`Error fetching dashboardFeatures: ${response.status}`);
};
