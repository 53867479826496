import { Link, useLocation, useNavigate } from '@reach/router';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconContainer,
  Icon as LibIcon,
} from '@socialchorus/shared-ui-components';
import cx from 'classnames';
import { TemplatesModal } from 'components/publisher/theme/TemplatesModal';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import * as React from 'react';
import { HoverDropdown } from 'shared/hover-dropdown/HoverDropdown';
import { Icon } from 'shared/Icon';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { Logo } from 'shared/icons';
import { OrgPicker } from 'shared/pickers/OrgPicker';
import { ProgramPicker } from 'shared/pickers/ProgramPicker';
import { RegionPicker } from 'shared/pickers/RegionPicker';
import { NewJourneyModal } from 'App/Program/Main/Journey/JourneyListPage/NewJourneyModal';
import styles from './layout.module.css';

const AccountMenu: React.FC = () => {
  const program = useProgram();
  const hasLogo = program.logoImageUrl && program.logoImageUrl.length;
  return (
    <HoverDropdown
      openDelay="click"
      dropdownRenderProp={() => (
        <div
          className={cx(styles.programPickerDropdown, {
            [styles.withLogo]: hasLogo,
          })}
        >
          <div>
            {hasLogo && (
              <img
                className={styles.logo}
                src={program.logoImageUrl}
                alt="Program Logo"
                width="300"
                height="150"
              />
            )}
            <RegionPicker />
            <OrgPicker />
            <ProgramPicker />
            <a
              className={styles.GoToProgram}
              href={program.url}
              rel="noreferrer"
              target="_blank"
            >
              Go to <strong>{program.name}</strong>
            </a>
          </div>
        </div>
      )}
      dropdownClassName="dropdown-align-right"
    >
      <div className={styles.ProgramTrigger}>
        <img src={program.iconImageUrl} alt={program.name} />
        <span>{program.name}</span>
        <div>
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.88916 1.7778L7.00027 6.22225L13.1114 1.7778"
              stroke="currentColor"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </div>
    </HoverDropdown>
  );
};

const PurePublishButton = React.forwardRef<
  HTMLButtonElement,
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>((props, ref) => {
  return (
    <button type="button" className={styles.publishButton} {...props} ref={ref}>
      <span className={styles.publishButtonIcon}>
        <SVGIcon name="Create" />
      </span>
      <span className={styles.publishButtonLabel}>Create</span>
    </button>
  );
});

const PublishButton: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { id: programId } = useProgram();
  const {
    permissions: { publishAccess, journeysAccess, manageAudienceAccess },
  } = usePermissions();

  const journeysEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Journeys.Enabled'
  ).data?.value;

  const createPopoverEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.CreatePopover.Enabled'
  ).data?.value;

  const permissionsServiceEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.Service'
  ).data?.value;

  const showJourneys =
    journeysEnabled && permissionsServiceEnabled && journeysAccess;

  const campaignTemplatesModalOpen = location.hash === '#campaigns/create';
  const journeysTemplatesModalOpen = location.hash === '#journeys/create';

  const links: {
    to: string;
    label: string;
    icon: string;
    // if true, will use navigate() onclick instead of Link to={}, this is to fix FE-14171.
    // For some reason, the Link component does not play nice with the hash nav to open this campaign modal, and on some routes it will auto-reroute on click to the current route, immediately removing the new hash.
    useNavigate?: boolean;
  }[] = [];
  if (publishAccess) {
    links.push({
      to: '#campaigns/create',
      label: 'Campaign',
      icon: 'article',
      useNavigate: true,
    });
  }
  if (showJourneys) {
    links.push({
      to: '#journeys/create',
      label: 'Journey',
      icon: 'family_history',
      useNavigate: true,
    });
  }
  if (manageAudienceAccess) {
    links.push({
      to: `/${programId}/app/people/audiences/new`,
      label: 'Audience',
      icon: 'people',
    });
  }

  const modals = (
    <>
      {campaignTemplatesModalOpen && (
        <TemplatesModal hide={() => navigate('#')} />
      )}
      {journeysTemplatesModalOpen && (
        <NewJourneyModal onClose={() => navigate('#')} />
      )}
    </>
  );

  if (!createPopoverEnabled) {
    return (
      <div>
        <PurePublishButton
          onClick={() => navigate('#campaigns/create')}
          data-test="open-create-campaign-modal"
        />
        {modals}
      </div>
    );
  }

  return (
    <>
      <div>
        {links.length === 1 ? (
          <PurePublishButton
            onClick={() => navigate(links[0].to)}
            data-test="open-create-campaign-modal"
          />
        ) : (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <PurePublishButton />
            </DropdownMenuTrigger>

            <DropdownMenuContent
              align="start"
              side="right"
              sideOffset={8}
              className={styles.publishDropdownContainer}
            >
              <div
                className={cx(
                  styles.publishHeader,
                  'text-subheading-bold text-black-90'
                )}
              >
                Create:
              </div>
              <div className={styles.publishDropdownContent}>
                {links.map((link) => {
                  const Component = link.useNavigate ? 'button' : Link;
                  return (
                    <DropdownMenuItem key={link.to} asChild>
                      <Component
                        to={link.useNavigate ? '' : link.to}
                        onClick={
                          link.useNavigate ? () => navigate(link.to) : undefined
                        }
                        className={styles.publishDropdownItem}
                      >
                        <IconContainer
                          color="purple"
                          variant="light"
                          iconName={link.icon}
                        />
                        <div
                          className={cx(
                            styles.publishDropdownLabel,
                            'text-subheading-bold text-black-90'
                          )}
                        >
                          {link.label}
                        </div>
                        <LibIcon>chevron_forward</LibIcon>
                      </Component>
                    </DropdownMenuItem>
                  );
                })}
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
      {modals}
    </>
  );
};

const MainLink: React.FC<{
  to: string;
  label: string;
  icon: string;
  additionalPartials?: string[];
}> = ({ additionalPartials = [], ...link }) => {
  const location = useLocation();
  const { id: programId } = useProgram();
  const libraryPreviewPathHack = !!additionalPartials.find((prefix) =>
    location.pathname.startsWith(prefix)
  );
  return (
    <Link
      to={`/${programId}/${link.to}`}
      getProps={({ isCurrent, isPartiallyCurrent }) => ({
        className: cx(styles.MainLink, {
          [styles.MainLinkActive]:
            isCurrent || isPartiallyCurrent || libraryPreviewPathHack,
        }),
      })}
    >
      <Icon iconName={link.icon} iconType="SVG" />
      <span className={styles.MainLinkLabel}>{link.label}</span>
      <span className={styles.MainLinkIndicator}>|</span>
    </Link>
  );
};

export const MainNavigation: React.FC = () => {
  const { id: programId } = useProgram();
  const {
    permissions: {
      calendarAccess,
      campaignsAccess,
      designsAccess,
      insightsAccess,
      peopleAccess,
      libraryAccess,
      configureAccess,
      publishAccess,
      manageTemplateAccess,
      libraryFontsAccess,
      journeysAccess,
      manageAudienceAccess,
    },
  } = usePermissions();

  const designsEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.Designs'
  ).data?.value;

  const fontsPageEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Library.FontsPage'
  ).data?.value;

  // designs UI only works in a new publisher
  const newPublisherEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.NewEditors'
  ).data?.value;

  const journeysEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Journeys.Enabled'
  ).data?.value;

  const permissionsServiceEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.Service'
  ).data?.value;

  const hasFontsPage = React.useMemo(() => {
    return fontsPageEnabled && libraryFontsAccess;
  }, [fontsPageEnabled, libraryFontsAccess]);

  const redirectLibraryTo = React.useMemo(() => {
    // redirect to the first available tab;
    if (manageTemplateAccess) {
      return 'templates';
    }
    if (hasFontsPage) {
      return 'fonts';
    }
    return '/';
  }, [hasFontsPage, manageTemplateAccess]);

  const showJourneys =
    journeysEnabled && permissionsServiceEnabled && journeysAccess;

  return (
    <aside className={styles.MainNavigation}>
      <Logo height="40" width="40" className={styles.FirstUpLogo} />
      {(publishAccess ||
        (journeysEnabled &&
          (showJourneys || manageAudienceAccess || publishAccess))) && (
        <div className={styles.CreateButton}>
          <PublishButton />
        </div>
      )}
      <nav className={styles.NavLinks}>
        {campaignsAccess && (
          <MainLink to="app/content" icon="Content" label="Campaigns" />
        )}
        {showJourneys && (
          <MainLink to="app/journeys" icon="Condition" label="Journeys" />
        )}
        {calendarAccess && (
          <MainLink to="app/calendar" icon="Calendar" label="Calendar" />
        )}
        {insightsAccess && (
          <MainLink to="app/insights" icon="Insights" label="Insights" />
        )}
        {peopleAccess && (
          <MainLink to="app/people" icon="People" label="People" />
        )}
        {designsEnabled && designsAccess && newPublisherEnabled && (
          <MainLink to="app/designs" icon="Library" label="Designs" />
        )}
        {libraryAccess && (manageTemplateAccess || hasFontsPage) && (
          <MainLink
            to={`app/library/${redirectLibraryTo}`}
            icon="Library"
            label="Library"
            additionalPartials={[`/${programId}/app/preview`]}
          />
        )}
      </nav>
      <div className={styles.MainNavFooter}>
        {configureAccess && (
          <MainLink to="configure" icon="Configure" label="Configure" />
        )}
        <AccountMenu />
      </div>
    </aside>
  );
};
