import React from 'react';
import { useJourneyState } from 'contexts/journeys/journey';
import {
  JourneyErrors,
  StepErrors,
  channelErrorsMap
} from 'models/journeys/journey-errors';

export type JourneyErrorKey =
  // Our prettier version is pretty old, so we need to disable the rule for this line
  // eslint-disable-next-line prettier/prettier
  | keyof ({ [K in StepErrors as keyof K]: never})
  | 'name'
  | 'rootStepId'
  | 'designProcessingErrors';

export type JourneyErrorsType = {
  errors?: JourneyErrors;
  errorsCount: number;
  errorsForStep: (stepId: string) => StepErrors | undefined;
  resolveErrors: (stepId: string, keys: Array<JourneyErrorKey>) => void;
  resolveChannelErrors: (stepId: string, channelName: keyof typeof channelErrorsMap) => void;
};

export const useJourneyValidationErrors = (): JourneyErrorsType => {
  const { errors, setErrors } = useJourneyState();

  const errorsCount: number = React.useMemo(() => {
    if (!errors?.graph) return 0;
    return Object.values(errors.graph)
      .map((stepErrors) => Object.values(stepErrors))
      .flat(2).length;
  }, [errors]);

  const errorsForStep = React.useCallback(
    (stepId: string) => {
      if (errors?.graph) {
        return errors.graph[stepId];
      }
      return undefined;
    },
    [errors]
  );

  const resolveErrors = React.useCallback(
    (stepId: string, keys: Array<JourneyErrorKey>) => {
      const newErrors = { ...errors };
      const stepErrors = newErrors.graph?.[stepId];
      if (stepErrors) {
        (keys as Array<keyof StepErrors>).forEach(
          (key) => delete stepErrors[key]
        );
        if (Object.keys(stepErrors).length === 0) {
          delete newErrors.graph?.[stepId];
        }
      }
      setErrors(newErrors);
    },
    [errors, setErrors]
  );

  const resolveChannelErrors = React.useCallback(
    (stepId: string, channelName: keyof typeof channelErrorsMap) => {
      const newErrors = { ...errors }
      const stepErrors = newErrors.graph?.[stepId]
      const channelErrorKeys = channelErrorsMap[channelName]

      if (stepErrors) {
        channelErrorKeys.forEach((key) => {
          delete stepErrors[key as keyof StepErrors]
        })

        if (Object.keys(stepErrors).length === 0) {
          delete newErrors.graph?.[stepId];
        }
      }

      setErrors(newErrors)
    },
    [errors, setErrors]
  )

  return {
    errors,
    errorsCount,
    errorsForStep,
    resolveErrors,
    resolveChannelErrors,
  };
};
