import React, { useMemo, useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { snakeCase } from 'lodash';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { useProgram } from '../../../../../../../contexts/program';
import { useJourneyQuery } from '../../../../../../../hooks/journeys/journeys';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';
import { NoDataChart } from '../../../../../../../shared/Charts/NoDataChart';

import style from './journey-user-activity-table.module.css';
import { useJourneyStepsUserActivityQuery } from '../../../../../../../hooks/journeyInsightsMetrics';
import { MAIcon } from '../../../../../../../shared/MAIcon';
import { DashboardFilterContext } from '../../../contexts/DashboardFilterContext';
import { UserJourneyActivity } from '../../../../../../../services/api-journey-metrics';
import { useDebounce } from '../../../../../../../hooks/useDebounce';

const columnHelper = createColumnHelper<UserJourneyActivity>();
const emptyArray: never[] = [];

interface IJourneyUserActivityTable {
  journeyId: string;
  stepId: string;
}

const renderCheckmark = (value: string | undefined) => {
  if (value === undefined) {
    return <span className={style.rightCell}>-</span>;
  }
  return value !== '0' && value !== undefined ? (
    <span className={style.rightCell}>
      <MAIcon name="check_circle" style={{ color: '#237804' }} />
    </span>
  ) : (
    <span className={style.rightCell}>
      <MAIcon name="cancel" style={{ color: '#a8071a' }} />
    </span>
  );
};

// const renderFlag = (value: boolean | undefined) => {
//   if (value === undefined) {
//     return <span>-</span>;
//   }
//   return value ? <MAIcon name="flag" /> : <span>-</span>;
// };

const renderDate = (value: string | undefined) => {
  try {
    if (value === undefined) {
      return (
        <span className={style.rightCell}>
          <MAIcon name="cancel" style={{ color: '#a8071a' }} />
        </span>
      );
    }
    const date = DateTime.fromISO(value);
    if (date.isValid) {
      return (
        <span className={style.rightCell}>{date.toFormat('yyyy/MM/dd')}</span>
      );
    }
    return (
      <span className={style.rightCell}>
        <MAIcon name="cancel" style={{ color: '#a8071a' }} />
      </span>
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return (
      <span className={style.rightCell}>
        <MAIcon name="cancel" style={{ color: '#a8071a' }} />
      </span>
    );
  }
};

export const JourneyUserActivityTable: React.FC = () => {
  const { appliedFilters } = React.useContext(DashboardFilterContext);
  const journeyId = useMemo(() => {
    return appliedFilters.campaign_id?.value?.[0]?.split('|')?.[0];
  }, [appliedFilters]);
  const stepId = useMemo(() => {
    return appliedFilters.step_id?.value?.[0];
  }, [appliedFilters]);

  if (!journeyId || !stepId) return null;
  return (
    <JourneyUserActivityTableInner journeyId={journeyId} stepId={stepId} />
  );
};

export const JourneyUserActivityTableInner: React.FC<IJourneyUserActivityTable> = ({
  journeyId,
  stepId,
}) => {
  const { id: programId } = useProgram();
  const columns = useMemo(
    () => [
      columnHelper.accessor('userName', {
        header: () => (
          <span className={cx(style.leftCell, style.subHeader)}>Name</span>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('emailAddress', {
        header: () => (
          <span className={cx(style.leftCell, style.subHeader)}>Email</span>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('userEmailDelivered', {
        header: () => <span>Email Delivered</span>,
        cell: ({ cell }) => renderCheckmark(cell.getValue()),
        sortingFn: 'basic',
      }),
      // columnHelper.accessor('userEmailSkipped', {
      //   header: () => <span>Email Skipped</span>,
      //   cell: ({ cell }) => renderFlag(cell.getValue()),
      // }),
      // columnHelper.accessor('userEmailBounced', {
      //   header: () => <span>Email Bounced</span>,
      //   cell: ({ cell }) => renderFlag(cell.getValue()),
      // }),
      // columnHelper.accessor('userEmailUnsubscribed', {
      //   header: () => <span>Email Unsubscribed</span>,
      //   cell: ({ cell }) => renderFlag(cell.getValue()),
      // }),
      columnHelper.accessor('userEmailOpened', {
        header: () => <span>Email Opened</span>,
        cell: ({ cell }) => renderCheckmark(cell.getValue()),
        sortingFn: 'basic',
      }),
      columnHelper.accessor('userEmailClicked', {
        header: () => <span>Email Clicked</span>,
        cell: ({ cell }) => renderCheckmark(cell.getValue()),
        sortingFn: 'basic',
      }),
      columnHelper.accessor('userNotificationDelivered', {
        header: () => <span>Notif. Delivered</span>,
        cell: ({ cell }) => renderCheckmark(cell.getValue()),
        sortingFn: 'basic',
      }),
      columnHelper.accessor('userNotificationOpened', {
        header: () => <span>Notif. Opened</span>,
        cell: ({ cell }) => renderCheckmark(cell.getValue()),
        sortingFn: 'basic',
      }),
      // columnHelper.accessor('userNotificationClick', {
      //   header: () => <span>Notif. Clicked</span>,
      //   cell: ({ cell }) => renderCheckmark(cell.getValue()),
      // }),
      columnHelper.accessor('userPushDelivered', {
        header: () => <span>Push Delivered</span>,
        cell: ({ cell }) => renderCheckmark(cell.getValue()),
        sortingFn: 'basic',
      }),
      columnHelper.accessor('userPushOpened', {
        header: () => <span>Push Opened</span>,
        cell: ({ cell }) => renderCheckmark(cell.getValue()),
        sortingFn: 'basic',
      }),
      columnHelper.accessor('userAcknowledgedDate', {
        header: () => <span>Acknowledged</span>,
        cell: ({ cell }) => renderDate(cell.getValue()),
        sortingFn: 'basic',
      }),
      columnHelper.accessor('pollRespondedDate', {
        header: () => <span>Poll Responded</span>,
        cell: ({ cell }) => renderDate(cell.getValue()),
        sortingFn: 'basic',
      }),
    ],
    []
  );

  const { data: journey, isLoading } = useJourneyQuery({
    programId,
    journeyId: Number(journeyId),
  });

  const [sorting, setSorting] = useState<SortingState>([]);
  const order = useMemo(() => {
    if (sorting.length < 1) {
      return undefined;
    }
    const [sort] = sorting;
    const { id, desc } = sort;

    return { sortId: snakeCase(id), sortDir: desc ? 'DESC' : 'ASC' };
  }, [sorting]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 12,
  });

  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, 1000);

  const {
    data: journeyStepUserActivity,
    meta,
    isLoading: isLoadingUserActivity,
  } = useJourneyStepsUserActivityQuery({
    journeyId,
    activationId: journey?.currentActivationId,
    stepId,
    query: {
      sortId: order?.sortId,
      sortDir: order?.sortDir,
      page: pagination?.pageIndex,
      search: debouncedSearch,
    },
  });

  const data = useMemo(() => {
    if (isLoadingUserActivity) return [];
    return journeyStepUserActivity ?? [];
  }, [journeyStepUserActivity, isLoadingUserActivity]);

  const table = useReactTable({
    columns,
    data: data ?? emptyArray,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualSorting: true,
    manualPagination: true,
    rowCount: meta?.totalRecords ?? 0,
    state: {
      sorting,
      pagination,
    },
  });

  if (isLoading) {
    return <LoadingChart />;
  }

  if (
    journeyId === undefined ||
    (journey?.liveGraph?.steps || journey?.draftGraph?.steps) === undefined
  ) {
    return <NoDataChart />;
  }

  const renderSortIcon = (sort: string | boolean) => {
    return {
      asc: <MAIcon name="arrow_upward_alt" className={style.sortIcon} />,
      desc: <MAIcon name="arrow_downward_alt" className={style.sortIcon} />,
      false: <MAIcon name="swap_vert" className={style.sortIcon} />,
    }[sort as string];
  };

  return (
    <>
      <div className={style.titleContainer}>
        <h1 className={style.title}>User Activity</h1>
        <p className={style.subtitle}>
          This table shows how users received and interacted with the
          communication for the selected journey step, including delivery
          details and engagement metrics.
        </p>
      </div>
      <div className={style.tableauTableWidgetContainer}>
        <div className={style.tableauTableWidgetHead}>
          <input
            type="text"
            aria-label="search users"
            className={style.searchInput}
            placeholder="Search users..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className={style.tableauTableWidgetBody}>
          <table
            className={cx(
              style.tableauTableWidgetScrollable,
              style.tableauTableWidget
            )}
          >
            <thead>
              {table.getHeaderGroups().map((hg) => {
                return (
                  <tr key={hg.id}>
                    {hg.headers.map((h) => (
                      <th key={h.id} className={style.activityTableHeader}>
                        {flexRender(h.column.columnDef.header, h.getContext())}
                        {h.column.getCanSort() && (
                          <button
                            type="button"
                            onClick={h.column.getToggleSortingHandler()}
                            className={style.sortIcon}
                          >
                            {renderSortIcon(h.column.getIsSorted())}
                          </button>
                        )}
                      </th>
                    ))}
                  </tr>
                );
              })}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={style.tableauTablePagerContainer}>
          <button
            type="button"
            disabled={!table.getCanPreviousPage()}
            className={cx({ [style.hidden]: !table.getCanPreviousPage() })}
            onClick={() => {
              table.previousPage();
            }}
          >
            <MAIcon name="arrow_left" />
          </button>
          <span>
            Page {pagination.pageIndex + 1} of {table.getPageCount()}
          </span>
          <button
            type="button"
            disabled={!table.getCanNextPage()}
            className={cx({ [style.hidden]: !table.getCanNextPage() })}
            onClick={() => {
              table.nextPage();
            }}
          >
            <MAIcon name="arrow_right" />
          </button>
        </div>
      </div>
    </>
  );
};
