import { Link } from '@reach/router';
import cx from 'classnames';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as React from 'react';
import { BarChart, FlowChart } from 'shared/icons';
import { Text } from '../../../../../DesignSystem/Typography';
import {
  getFeatureMetaData,
  useTableauDashboardFeatures,
} from '../../../../../hooks/tableau-dashboard';
import styles from './workbook-banner.module.css';

export type PropType = {
  workbook: Tableau.Workbook;
  programId: number;
};

type WorkbookBannerMetaData = {
  caption: string;
  logo: string;
};

const FEATURE_MAPPING: {
  [key: string]: WorkbookBannerMetaData | undefined;
} = {
  'workbook.banner': {
    caption: '',
    logo: '',
  },
};

export const WorkbookBanner: React.FC<PropType> = ({ workbook, programId }) => {
  const { data: features, isLoading } = useTableauDashboardFeatures({
    dashboardId: workbook.defaultViewId,
  });

  const [caption, setCaption] = React.useState<string>('View Report');
  const [logo, setLogo] = React.useState<string>('');

  React.useEffect(() => {
    if (isLoading || features === undefined) return;
    features.forEach(({ key, metaData: metaDataJson }) => {
      if (FEATURE_MAPPING[key]) {
        const metaData = getFeatureMetaData<WorkbookBannerMetaData>(
          metaDataJson
        );
        setCaption(metaData.caption);
        setLogo(metaData.logo);
      }
    });
  }, [features, isLoading]);

  return (
    <div className={cx(styles.flexWrap)}>
      <article className={cx(styles.wrapper)}>
        <Link
          to={`/${programId}/app/tableau/workbooks/${workbook.id}/dashboards/${workbook.defaultViewId}`}
          role="button"
          aria-label="View Dashboard"
        >
          <Flex start className={cx(styles.workbookCol, styles.workbookHeader)}>
            <div>
              {logo.indexOf('bar') !== -1 ? <BarChart /> : <FlowChart />}
            </div>
            <div className={styles.title} title={workbook.name}>
              {workbook.name}
            </div>
          </Flex>
          <Flex start>
            <Text className={{ Body: true }}>{caption}</Text>
          </Flex>
        </Link>
      </article>
    </div>
  );
};
