import React, { createContext, useContext } from 'react';
import { Post, processChange } from 'models/publisher/post';
import { useUniqueId } from 'hooks/useUniqueId';
import { defaultTemplate } from 'models/library';
import { TemplateType } from 'models/template';
import { usePermissions } from 'contexts/permissions';
import { CloudbeesFlags } from 'models/feature-flag';
import { usePost } from 'hooks/publisher';
import { LoadingScreen } from 'shared/LoadingScreen';
import { useFieldVariables } from 'hooks/publisher/useFieldVariables';
import { useNotificationCenterEnabled } from 'hooks/notification-center';
import { useTemplate } from './template';
import {
  OrchestrateSettingsEditorContext,
  SettingsEditor,
} from './publisher/orchestrate/use-settings';
import { useFeatureFlags } from './feature-flags';

type TemplateAssetContextData = {
  post: Post;
  update: (changes: Partial<TemplateType>) => void;
  error?: string;
};

const contextPrototype: TemplateAssetContextData = {
  post: { ...defaultTemplate.asset.template } as Post,
  update: () => {},
};

const TemplateAssetContext = createContext(contextPrototype);

export const useTemplateAsset = (): typeof contextPrototype => {
  const context = useContext(TemplateAssetContext);
  if (context === undefined) {
    throw new Error(
      'Template asset context hooks require a containing TemplateAssetProvider'
    );
  }
  return context;
};

export const TemplateAssetProvider: React.FC<{
  programId: number;
}> = ({ children, programId }) => {
  const { template, update, initialisedFromPost } = useTemplate();
  const { asset } = template;
  const uuid = useUniqueId();

  const [updatedAsset, setUpdatedAsset] = React.useState<boolean>();

  // this is used to get default values
  // and as a solution to have the template data in the "usePublisher" hook
  const { post, ...context } = usePost(programId, 'new', true);

  React.useEffect(() => {
    if (template.id === 'new' && !updatedAsset && post) {
      if (!initialisedFromPost) {
        update({
          asset: {
            template: {
              ...asset.template,
              settings: post.settings,
              blocks: post.blocks,
              callToAction: post.callToAction,
              styles: post.styles,
            },
          },
        });
        setUpdatedAsset(true);
      }
    }

    if (!template.identifier && template.id === 'new') {
      update({ identifier: uuid });
    }
  }, [
    asset.template,
    template,
    update,
    uuid,
    asset,
    updatedAsset,
    post,
    initialisedFromPost,
  ]);
  const { fromPost: varsFromPost } = useFieldVariables(asset.template as Post);
  const notificationCenterEnabled = useNotificationCenterEnabled();
  const updateAsset = React.useCallback(
    (changes: Partial<TemplateType>) => {
      const updatedPost = {
        ...asset.template,
        ...changes,
      } as Post;
      const currentPost = processChange(
        updatedPost,
        {
          post: updatedPost,
          varsFromPost,
        },
        notificationCenterEnabled
      );
      update({
        asset: {
          template: {
            ...currentPost,
          },
        },
      });

      context.update({ ...asset.template, ...changes } as Post);
    },
    [asset.template, context, update, varsFromPost, notificationCenterEnabled]
  );

  const updateSettings = React.useCallback<SettingsEditor['update']>(
    (settings) => {
      // update post in the publisher context
      context.update({
        settings: {
          ...asset.template.settings,
          ...post?.settings,
          ...settings,
        },
      });
      // update template
      update({
        asset: {
          ...asset,
          template: {
            ...asset.template,
            settings: {
              ...asset.template.settings,
              ...settings,
            },
          },
        },
      });
    },
    [asset, context, post?.settings, update]
  );

  const { permissions } = usePermissions();
  const featureFlags = useFeatureFlags();

  if (template.id === 'new' && !post) {
    return <LoadingScreen />;
  }
  const value: TemplateAssetContextData = {
    post:
      template.id === 'new'
        ? (post as Post)
        : (template.asset.template as Post),
    ...context,
    update: updateAsset,
  };

  return (
    <TemplateAssetContext.Provider value={value}>
      <OrchestrateSettingsEditorContext.Provider
        value={{
          permissions,
          options: {
            canChangePublicationDate: false,
            canChangeArchiveDate: false,
            defaultNotificationText: asset.template.callToAction.title,
            defaultNotificationPreviewText: asset.template.callToAction.summary,
          },
          featureFlags,
          cloudbeesFlags: {} as CloudbeesFlags,
          settings: asset.template.settings,
          update: updateSettings,
        }}
      >
        {children}
      </OrchestrateSettingsEditorContext.Provider>
    </TemplateAssetContext.Provider>
  );
};
