import React from 'react';
import layout from 'DesignSystem/Layout/layout.module.css';
import {
  PREVIEW_TABS,
  PreviewContext,
  PreviewTab,
  PreviewType,
} from 'contexts/publisher/compose/preview';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Switcher } from 'components/publisher/previews/Switcher';
import { Box } from 'DesignSystem/Components';
import {
  isLinksFieldDataArray,
  isLinksImageProcessing,
} from 'models/publisher/block';
import compose from 'components/publisher/theme/compose.module.css';
import cx from 'classnames';
import { useFlashMessage } from 'contexts/flasher';
import { DesignContext, useDesign } from 'contexts/design';
import { Flex } from 'DesignSystem/Layout/Flex';
import { VariablePreviewOptionsComponent } from 'components/publisher/pages/Preview/VariablePreviewOptions';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { NewVariablePreviewOptions } from 'components/publisher/pages/Preview/personalized-field/NewVariablePreviewOptions';
import { Targets } from 'models/donkey';
import { useNotificationCenterEnabled } from 'hooks/notification-center';
import { SendTestCampaignButton } from 'App/Program/Editors/Publisher/NewReview/ContentPreviewSection/SendTestCampaign';
import styles from './CommunicationPreview.module.css';

import { useJourneyContentDesigner } from '../JourneyContentDesigner/JourneyContentDesignProvider';
import {
  CheckedChannelsType,
  SendTestModal,
} from '../../../../../components/delivery/send-test/send-test-modal';
import { useSendTestCommunicationSettings } from '../../../../../components/delivery/send-test/hooks';

export const SendTestJourneyContent: React.FC = () => {
  const { design, status, isProcessing } = useDesign();
  const sendTestCommunicationSettings = useSendTestCommunicationSettings();

  // get delivery channels here when the journey omni-channel feature is implemented
  const [showTestCampaignModal, setShowTestCampaignModal] = React.useState(
    false
  );
  const notificationCenterEnabled = useNotificationCenterEnabled();
  const initialCheckedChannels = { email: true } as Partial<
    CheckedChannelsType
  >;
  if (notificationCenterEnabled) {
    initialCheckedChannels.push = true;
  }
  const visibleBlocksForEmail = design.blocks.filter(
    (block) =>
      block?.target === undefined ||
      !block?.target.excluded.includes(Targets.email)
  ).length;

  if (visibleBlocksForEmail < 1) {
    return null;
  }
  const blockHasProcessingLinkImage = () =>
    design.blocks.some((block) => {
      if (
        block.name === 'links' &&
        isLinksFieldDataArray(block.field_data?.links)
      ) {
        return isLinksImageProcessing(block.field_data.links);
      }
      return false;
    });
  const toggleShow = () => setShowTestCampaignModal(!showTestCampaignModal);
  return (
    <>
      <SendTestCampaignButton
        disabled={
          design.blocks.length === 0 ||
          (isProcessing && !blockHasProcessingLinkImage) ||
          status.isSaving
        }
        processing={isProcessing}
        label="Send a Test Communication"
        onClick={toggleShow}
      />

      {showTestCampaignModal && (
        <SendTestModal
          settings={sendTestCommunicationSettings}
          initialCheckedChannels={initialCheckedChannels}
          toggleShow={toggleShow}
        />
      )}
    </>
  );
};

interface CommunicationPreviewProps {
  fullSize?: boolean;
}

export const CommunicationPreview: React.FC<CommunicationPreviewProps> = ({
  fullSize,
}) => {
  const { setFlashMessage } = useFlashMessage();

  const { inlineEditingEnabled } = useJourneyContentDesigner();
  const personalizedFieldsV2Enabled = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.PersonalizedFieldsV2'
  ).data?.value;

  const designContext = useDesign();
  const {
    tabs,
    tab,
    setTab,
    html,
    isLoading: isPreviewLoading,
    errors,
    webFontsEnabled,
    toggleWebFonts,
    previewAs,
    setPreviewAs,
  } = React.useContext(PreviewContext);

  React.useEffect(() => {
    setTab(PREVIEW_TABS.web);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iframe = React.useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = React.useState(2000);

  const onLoad = () => {
    const scrollHeight = iframe.current?.contentDocument?.body?.scrollHeight;
    if (scrollHeight) setHeight(scrollHeight + 10);
  };

  React.useEffect(() => {
    if (!errors.length) return;

    setFlashMessage({
      severity: 'error',
      message: errors.map((e) => e.message).join('\n'),
    });
  }, [errors, setFlashMessage]);

  const handleSetTab = (newTab: PreviewTab) => {
    if ((newTab.name === PreviewType.email) === webFontsEnabled) {
      toggleWebFonts();
    }
    setTab(newTab);
  };

  const classList = fullSize
    ? [compose.container]
    : [layout.Flex, layout.Spread];

  return (
    <DesignContext.Provider value={designContext}>
      <div className={cx(classList)}>
        {inlineEditingEnabled && (
          <Flex column start className={styles.previewOptions}>
            {!personalizedFieldsV2Enabled && (
              <VariablePreviewOptionsComponent
                blocks={designContext.design.blocks}
                previewAs={previewAs}
                setPreviewAs={setPreviewAs}
              />
            )}
            {personalizedFieldsV2Enabled && (
              <Box width={245} padding={[0, 0, 0, 3]}>
                <NewVariablePreviewOptions
                  hasPfFile={false}
                  hasMyselfAsCreator={false}
                />
              </Box>
            )}
            <SendTestJourneyContent />
          </Flex>
        )}
        <Box className={cx(styles.previewContainer, compose.main)}>
          <Flex center>
            {tab && (
              <tab.frame>
                {isPreviewLoading ? (
                  <div
                    className="flex-centered kai-flex-row"
                    style={{
                      margin: '100px auto',
                    }}
                  >
                    <LoadingSpinner />
                  </div>
                ) : (
                  <iframe
                    title="Preview"
                    srcDoc={html}
                    onLoad={onLoad}
                    ref={iframe}
                    style={{
                      width: '100%',
                      height: `${height}px`,
                      minHeight: '100%',
                    }}
                  />
                )}
              </tab.frame>
            )}
            {!designContext.status.isLoading && (
              <Switcher
                tab={tab}
                tabs={tabs}
                setTab={handleSetTab}
                className={styles.switcher}
              />
            )}
          </Flex>
        </Box>
      </div>
    </DesignContext.Provider>
  );
};
