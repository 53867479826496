import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Button, Icon } from '@socialchorus/shared-ui-components';
import { Box } from 'DesignSystem/Components';
import { KNOWLEDGE_BASE_URL } from 'utility/constants';
import { Blocks } from 'models/library';
import styles from './editor.module.css';
import { BlockPicker } from '../../BlockPicker';

export const DefaultContentBlock: React.FC = () => {
  // TODO: add a design when a block is inserted from the library

  const [insertingFromLibrary, setInsertingFromLibrary] = React.useState<
    boolean
  >(false);

  const selectFromLibrary = React.useCallback(
    (animate: (after: () => void) => void) => (items: Blocks[]) => {
      animate(() => {
        // eslint-disable-next-line no-alert
        alert(`Not implemented - insert items ${items}`);
      });
    },
    []
  );
  return (
    <Flex column center className={styles.defaultContentBlock}>
      <Box className={styles.lineHeight}>
        <Text.Body bold>This is your default content block</Text.Body>
      </Box>
      <Box padding={[14, 0]} center width={442} className={styles.lineHeight}>
        <Text.Body>
          Adding content here displays as fallback for any non-targeted users
          viewing the campaign. Leaving this variant blank hides the block from
          any non-targeted users.
        </Text.Body>
      </Box>

      <Box padding={[0, 0, 14, 0]}>
        <Button
          size="compact"
          variant="primary"
          label="Blocks"
          leftIcon={<Icon>add</Icon>}
        />
      </Box>
      <Flex column className={styles.checkOutTheKnowledgeBase}>
        <Text.Body block>Don&apos;t know where to start?</Text.Body>
        <Text.Body block>
          <a rel="noreferrer" target="_blank" href={KNOWLEDGE_BASE_URL}>
            Check out the Knowledge Base.
          </a>
        </Text.Body>
      </Flex>
      {insertingFromLibrary && (
        <BlockPicker
          useModal
          selectFromLibrary={selectFromLibrary}
          onCancel={() => setInsertingFromLibrary(false)}
        />
      )}
    </Flex>
  );
};
