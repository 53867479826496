import React, { useCallback, useMemo } from 'react';
import { ClickDropdown } from 'shared/ClickDropdown';
import styles from 'shared/FilterDropdown/filter-dropdown.module.css';
import { InfiniteLoadProps, InfiniteSelect } from 'shared/InfiniteSelect';
import { useProgram } from '../../../../../contexts/program';
import { OptionType } from '../../../../../hooks/common';
import { DashboardFilterTriggerButton } from './DashboardFilterTriggerButton';
import { useDebounce } from '../../../../../hooks/useDebounce';

type PropsType = {
  onChange: (value: string[]) => void;
  onSearchTermChange?: (value: string) => void;
  onClose?: () => void;
  options: OptionType[];
  label: string;
  clearDisabled?: boolean;
  hasClearButton?: boolean;
  searchEnabled?: boolean;
  searchPlaceholder?: string;
  selectedOptions?: OptionType[];
  hasClearSearchButton?: boolean;
  maxHeight?: number;
  multiSelect?: boolean;
  dynamicLabel?: boolean;
  onDropDownClose?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryHook?: any;
  queryHookProps?: { [key: string]: unknown };
  valueAccessor?: string;
  valueTransform?: (data: {
    [key: string]: string;
  }) =>
    | {
        label: string;
        value: string;
      }
    | undefined;
  initialValues?: string[];
  hasSearchBar?: boolean;
  searchKey?: string;
  localSearch?: boolean;
} & InfiniteLoadProps;

export const DashboardFilterDropdown: React.FC<PropsType> = (props) => {
  const {
    onChange,
    label,
    options: initialOptions,
    onSearchTermChange,
    selectedOptions: initialSelectedOptions,
    searchEnabled,
    searchPlaceholder,
    clearDisabled,
    hasClearSearchButton,
    onClose,
    maxHeight = 200,
    multiSelect = true,
    onDropDownClose,
    queryHook,
    queryHookProps,
    valueAccessor,
    valueTransform,
    initialValues,
    hasClearButton = true,
    searchKey,
    localSearch = false,
  } = props;
  const [selectedValues, setSelectedValues] = React.useState<string[]>(
    initialValues ?? []
  );
  const programId = useProgram().id;
  const onDismissRef: React.MutableRefObject<() => void> = React.useRef(
    () => {}
  );

  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const handleSearchTermChange = useCallback(
    (value: string) => {
      setSearchTerm(value);
      onSearchTermChange?.(value);
    },
    [onSearchTermChange]
  );

  const combinedProps = useMemo(() => {
    const queryprops = { ...queryHookProps };
    if (searchKey) {
      queryprops[searchKey] = debouncedSearchTerm;
    }
    return queryprops;
  }, [searchKey, queryHookProps, debouncedSearchTerm]);

  const executeQueryHook = useCallback(() => {
    return queryHook !== undefined
      ? queryHook({ programId, ...combinedProps })
      : {
          data: initialOptions,
          isLoading: false,
          hasNextPage: false,
          isFetchingNextPage: false,
          fetchNextPage: () => {},
        };
  }, [combinedProps, initialOptions, programId, queryHook]);

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = executeQueryHook();

  const options: OptionType[] = React.useMemo(() => {
    if (
      data === undefined ||
      queryHook === undefined ||
      valueTransform === undefined
    ) {
      return initialOptions;
    }

    const map = (valueAccessor !== undefined
      ? valueAccessor
          .split('.')
          .reduce((o, i) => o?.[i], data)
          ?.map(valueTransform) ?? initialOptions
      : data.map(valueTransform)
    ).filter((x: unknown) => x !== undefined);
    return localSearch
      ? map?.filter(
          ({ label: searchLabel }: { label: string }) =>
            searchLabel?.toUpperCase()?.match(searchTerm?.toUpperCase()) !==
            null
        )
      : map;
  }, [
    data,
    initialOptions,
    localSearch,
    queryHook,
    searchTerm,
    valueAccessor,
    valueTransform,
  ]);

  const selectedOptions: OptionType[] = React.useMemo(() => {
    return (
      initialSelectedOptions ??
      options.filter((x) => selectedValues.includes(x.value))
    );
  }, [initialSelectedOptions, options, selectedValues]);

  const onSelectedIdsChange = React.useCallback(
    (ids: string[]) => {
      if (multiSelect) {
        setSelectedValues(ids);
        onChange(ids);
      } else {
        const singleIdArray = ids.length <= 0 ? [] : [ids[ids.length - 1]];
        setSelectedValues(singleIdArray);
        onChange(singleIdArray);
      }
    },
    [multiSelect, onChange]
  );
  const rowIds = options.map((x) => x.value);
  const renderRow = React.useCallback(
    (value: string) => {
      const lbl = options.find((opt) => opt.value === value)?.label;
      return (
        <div className={styles.filterItemLabel}>
          <span>{lbl}</span>
        </div>
      );
    },
    [options]
  );

  const selectedIds = React.useMemo(() => {
    return selectedOptions.map(({ value }) => value);
  }, [selectedOptions]);

  const dropdown = React.useMemo(() => {
    return (
      <InfiniteSelect
        className={styles.filterItemsWrapper}
        rowIds={rowIds}
        rowRenderProp={renderRow}
        maxHeight={maxHeight}
        itemHeight={32}
        clearDisabled={clearDisabled}
        hasClearButton={hasClearButton}
        hasClearSearchButton={hasClearSearchButton}
        selectedIds={selectedIds}
        searchEnabled={searchEnabled && !!handleSearchTermChange}
        searchTerm={searchTerm}
        searchPlaceholder={searchPlaceholder}
        onSearchTermChange={handleSearchTermChange}
        onSelectedIdsChange={onSelectedIdsChange}
        itemClassName={styles.filterItem}
        dismissButton="Done"
        onDismissRef={onDismissRef}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        multiSelect={multiSelect}
      />
    );
  }, [
    rowIds,
    renderRow,
    maxHeight,
    clearDisabled,
    hasClearButton,
    hasClearSearchButton,
    selectedIds,
    searchEnabled,
    handleSearchTermChange,
    searchTerm,
    searchPlaceholder,
    onSelectedIdsChange,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    multiSelect,
  ]);

  const dropdownRenderProp = React.useCallback(
    (dismiss: () => void) => {
      onDismissRef.current = dismiss;
      return <div className="filter-dropdown">{dropdown}</div>;
    },
    [dropdown]
  );

  const selectedLabels = selectedOptions.map(
    ({ label: optionLabel }) => optionLabel
  );

  return (
    <div className={styles.dropdownWrapper}>
      <ClickDropdown
        dropdownRenderProp={dropdownRenderProp}
        onClose={onDropDownClose}
      >
        <DashboardFilterTriggerButton
          name={label}
          value={selectedLabels}
          onClose={onClose}
        />
      </ClickDropdown>
    </div>
  );
};
