import ReportImage from 'assets/images/journey-insights-report-landing.png';
import * as React from 'react';
import { Box } from '../../../../../DesignSystem/Components';
import { FlexItem } from '../../../../../DesignSystem/Layout/Flex';
import styles from './filter-landing.module.css';

type DashboardFilterLandingProps = {
  workbookName: string;
  dashboardId: string;
  showLanding: boolean;
  children: React.ReactNode;
};

type LandingPageProps = {
  header: string;
  body: string;
  img: string;
};

const landingPageDefaults: Record<string, LandingPageProps> = {
  executive: {
    header: 'What would you like to see?',
    body: 'Choose a time range to get started. Use filters to narrow the data.',
    img: ReportImage,
  },
  journey: {
    header: 'No Journey selected yet.',
    body: `Choose a journey and timeframe to unlock detailed insights on
            performance and engagement.`,
    img: ReportImage,
  },
};

const getLandingPageDefaults = (name: string) => {
  const isJourney = name.toLowerCase().indexOf('journey') !== -1;
  return isJourney
    ? landingPageDefaults.journey
    : landingPageDefaults.executive;
};

export const DashboardFilterLanding: React.FC<DashboardFilterLandingProps> = ({
  workbookName,
  showLanding,
  children,
}) => {
  const landingPage = getLandingPageDefaults(workbookName);
  return (
    <Box>
      <FlexItem style={{ display: showLanding ? 'flex' : 'none' }}>
        <Box center className={styles.filterLandingBody}>
          <img src={landingPage.img} alt="Select filters to get started" />
          <h2 className={styles.filterLandingHeader}>{landingPage.header}</h2>
          <p className={styles.filterLandingText}>{landingPage.body}</p>
        </Box>
      </FlexItem>
      <FlexItem start={!showLanding}>{children}</FlexItem>
    </Box>
  );
};
