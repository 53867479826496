import React from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { MAIcon } from 'shared/MAIcon';
import { Button } from 'DesignSystem/Form';
import { Box } from 'DesignSystem/Components';
import { JourneyList } from '../JourneyList';

export const JourneyListPage: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate();
  return (
    <>
      <ListPage
        title="Journeys"
        breadcrumbs={[{ label: 'Journeys' }]}
        preserveSearch
        tabs={[
          { id: 'current', label: 'Current' },
          { id: 'archive', label: 'Archived' },
        ].map(({ id, label }) => ({
          to: id,
          label,
        }))}
        actionsOverride={
          <Box margin={['auto', 0]}>
            <Button
              onClick={() => navigate('#journeys/create')}
              icon={<MAIcon name="add" />}
              label="Create Journey"
            />
          </Box>
        }
      >
        <JourneyList />
      </ListPage>
    </>
  );
};
