import { Icon } from '@socialchorus/shared-ui-components';
import React, { useContext, useState } from 'react';
import { AudienceSelect } from 'components/audience/AudienceSelect';
import { usePermissions } from 'contexts/permissions';
import { Button, FormSection, FormSubsection } from 'DesignSystem/Form';
import { BoxIntegrationData } from 'models/box-integration';
import { CharacterCounter } from 'shared/CharacterCounter';
import { useBoxRootFolder } from 'shared/Box/useBoxRootFolder';
import { PickerModal } from 'shared/Box/PickerModal';
import { TextInput } from 'shared/TextInput';
import { BoxFolderFormContext } from './context';
import styles from './styles.module.css';

export { BoxFolderFormContext };
export { useCheckBoxFolderConflict } from './useCheckBoxFolderConflict';

export const BoxFolderForm: React.FC = () => {
  const { data: rootFolderData } = useBoxRootFolder();
  const { permissions } = usePermissions();

  const {
    name,
    description,
    audiences,
    hiddenAudienceIds,
    boxFolder,
    savedBoxFolderName,
    onNameChange,
    onDescriptionChange,
    onAudiencesChange,
    onBoxFolderChange,
    isCheckingBoxFolderConflict,
    boxFolderErrorMessage,
  } = useContext(BoxFolderFormContext);

  const [showPickerModal, setShowPickerModal] = useState(false);

  const onSelectFolder = (folder: BoxIntegrationData) => {
    onBoxFolderChange(folder);
    setShowPickerModal(false);
  };

  const SelectionView = () => {
    if (savedBoxFolderName) {
      return (
        <span className={styles.folderSubsection__Subheading}>
          <b>Selected:</b> {savedBoxFolderName}
        </span>
      );
    }

    return (
      <>
        {boxFolder && (
          <span className={styles.folderSubsection__Subheading}>
            <b>Selected:</b> {boxFolder.name}
          </span>
        )}
        <Button
          label={boxFolder ? 'Replace Folder' : 'Select Folder'}
          isLoading={isCheckingBoxFolderConflict}
          onClick={() => setShowPickerModal(true)}
        />
      </>
    );
  };

  return (
    <FormSection>
      <FormSubsection title="Name" divider={false}>
        <TextInput
          onChange={onNameChange}
          value={name}
          className={styles.textInput}
          maximum={50}
        />
        <CharacterCounter
          className={styles.charCounter}
          count={name?.length || 0}
          maxCharacters={50}
        />
      </FormSubsection>

      <FormSubsection title="Description" divider={false}>
        <TextInput
          onChange={onDescriptionChange}
          value={description}
          className={styles.textInput}
          maximum={150}
        />
        <CharacterCounter
          className={styles.charCounter}
          count={description?.length || 0}
          maxCharacters={150}
        />
      </FormSubsection>

      <FormSubsection title="Audiences" divider={false}>
        <span className={styles.audienceSubsection__Subheading}>
          Select at least one audience to link to this folder
        </span>
        <AudienceSelect
          variant="pills"
          icon={<Icon>search</Icon>}
          placeholder="Select audience(s)"
          selectedAudiences={audiences}
          onSelectedAudiencesChange={onAudiencesChange}
          alwaysDisplaySelected={false}
        />
        {hiddenAudienceIds.length > 0 && (
          <span className={styles.audienceSubsection__HiddenAudience}>
            + {hiddenAudienceIds.length} hidden audience(s)
          </span>
        )}
      </FormSubsection>

      <FormSubsection title="Box Folder" divider={false}>
        {SelectionView()}
        {boxFolderErrorMessage && (
          <p role="alert" className={styles.error}>
            {boxFolderErrorMessage}
          </p>
        )}
      </FormSubsection>

      {showPickerModal && (
        <PickerModal
          folderId={rootFolderData?.rootFolderId.toString()}
          entityText="box folder"
          description="Select one folder to link in Firstup"
          type={['folder']}
          onSubmit={onSelectFolder}
          onCancel={() => setShowPickerModal(false)}
          restrictByPermission={!permissions.boxManageAllContentAccess}
        />
      )}
    </FormSection>
  );
};
